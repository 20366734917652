/* eslint-disable max-len */
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TemasDataService } from './temas-data.service';
import { Tema } from '../../../shared/models/tema/tema.model';
import { ModulosOrganizacion } from '../../../shared/models/tema/modulo.model';
import { GtagService } from '../../gtag/gtag.service';
import { EditionModeService } from '../../edition-mode.service';
import { LocalCacheService } from '../../../core/load-cache/load-cache.service';
import { EnvService } from '../../env.service';
import { MateriaManagementService } from '../materia/materia-management.service';
import { log } from 'console';

const ID_TIPO_APRENDIZAJE = 11;

@Injectable({
  providedIn: 'root'
})
export class TemasManagementService implements OnDestroy {

  private scrollPosition: any;

  indiceSeccion = [];
  seccionesConRecursos: any[] = [];
  recursoPreviewStatus = null;
  contextViewStatus = null;

  modulosList = []

  temas$: Observable<Tema[]>;
  otrosTemas$: Observable<Tema[]>;
  tema$: Observable<Tema>;
  recurso$: Observable<ModulosOrganizacion>;
  anotaciones$: Observable<any[]>;
  anotacionesShow$: Observable<boolean>;
  highlights$: Observable<any[]>;
  contextView$: Observable<any>;

  public plantillaData = null;
  public fullscreen = false;
  public isRecursoCompacto = false;

  private _temas: BehaviorSubject<Tema[]> = new BehaviorSubject<Tema[]>([]);
  private _otrosTemas: BehaviorSubject<Tema[]> = new BehaviorSubject<Tema[]>([]);
  private _tema: BehaviorSubject<Tema> = new BehaviorSubject<Tema>(null);
  private _recurso: BehaviorSubject<ModulosOrganizacion> = new BehaviorSubject<ModulosOrganizacion>(null);
  private _anotaciones: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private _anotacionesShow: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  private _highlights: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private _contextView: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private destroy$: Subject<void> = new Subject<void>();

  public urlTemaReturn = '';
  public componentVisible = 1;

  private tmpListaRercursos = [];

  lastAnnotation = null;

  constructor(
    private editionModeService: EditionModeService,
    private temasDataService: TemasDataService,
    private gtagService: GtagService,
    private materiaManagementService: MateriaManagementService,
    private localCacheService: LocalCacheService,
    private envService: EnvService
  ) {
    this.temas$ = this._temas.asObservable();
    this.otrosTemas$ = this._otrosTemas.asObservable();
    this.tema$ = this._tema.asObservable();
    this.recurso$ = this._recurso.asObservable();
    this.anotaciones$ = this._anotaciones.asObservable();
    this.anotacionesShow$ = this._anotacionesShow.asObservable();
    this.highlights$ = this._highlights.asObservable();
    this.contextView$ = this._contextView.asObservable();
  }

  get temas() {
    return this._temas.getValue();
  }

  get recurso() {
    return this._recurso.getValue();
  }

  set recurso(recurso: any) {
    this._recurso.next(recurso);
  }

  set temas(temas: Tema[]) {
    let temasObj = temas.map(tema => (new Tema(tema)));
    temasObj = this.indicesVirtuales(temasObj);
    this._temas.next(temasObj);
  }

  set tema(tema: Tema) {
    this.temaNext(tema);
  }

  get tema() {
    return this._tema.getValue();
  }

  get anotaciones() {
    return this._anotaciones.getValue();
  }

  get anotacionesShow() {
    return this._anotacionesShow.getValue();
  }

  set anotacionesShow(value: boolean) {
    this._anotacionesShow.next(value);
  }

  get contextView() {
    return this._contextView.getValue();
  }

  set contextView(value: boolean) {
    this._contextView.next(value);
  }

  get highlights() {
    return this._highlights.getValue();
  }

  get otrosTemas() {
    return this._otrosTemas.getValue();
  }

  set otrosTemas(temas: Tema[]) {
    this.otrosTemasNext(temas);
  }

  getOtrosTemas(idMateria, userId) {
    this.temasDataService.getOtrosTemas(idMateria).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          let otrosTemas: Tema[] = null;
          if (res) {
            otrosTemas = [].concat.apply([], res.map(materia => {
              const arrayTemas: Tema[] = materia?.temas;
              arrayTemas.forEach(tema => {
                tema.idParent = materia?.id;
              });
              return arrayTemas;
            }));
          }
          this.otrosTemasNext(otrosTemas);
        }
      );
  }

  getTema(idTema: string) {
    this.temasDataService.getTema(idTema).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.temaNext(res);
        }
      );
  }

  getRecurso(idTema, idModulo, contextId) {
    this.temasDataService.getModulo(idTema, idModulo, contextId).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        modulo => {
          // uri = uri.Replace(protocolo, string.Empty).Replace(".zip", string.Empty);

          // url = $"{aulaURLs.ContenidosURL}/{uri}/{C.Contenido.Modulo.ENTRY_POINT_PRELOAD}{aulaURLs.ModulosURL}{modulo.Id}/";
          if (
            modulo.propiedades.tipoActividad &&
            modulo.propiedades.tipoActividad.valor.player &&
            modulo.propiedades.tipoActividad.valor.player.includes('fs1://') &&
            !modulo.propiedades.actividad
          ) {
            const paso1 = modulo.propiedades.tipoActividad.valor.player.replaceAll('fs1://', '');
            const paso2 = paso1.replaceAll('.zip', '');
            const paso3 = `/Contenido/${paso2}/Principal.html?_preload_data=/Contenido/Modulos/${modulo.id}/`;
            modulo.propiedades.actividad = paso3;
            console.error(`El modulo ${modulo.id} viene con la url del SCORM mal formada`);
          }
          this.recursoNext(modulo);
        }
      );
  }

  getRecursoWorkaround(idTema, idModulo): Observable<any> {
    return this.temasDataService.getModulo(idTema, idModulo, null);
  }

  getModuloSeccion(idTema, idModulo): Observable<any> {
    return this.temasDataService.getModuloSeccion(idTema, idModulo);
  }

  crearTema(nuevoTema: any): Observable<any> {
    this.localCacheService.expire(`MATERIADATASERVICE-GETMATERIA-${nuevoTema.materiaId}`).pipe(take(1), takeUntil(this.destroy$)).subscribe();
    return this.temasDataService.createTema(nuevoTema);
  }

  updateTema(tema: Tema, materiaId: number) {
    setTimeout(() => {
      this.localCacheService.expire(`TEMASDATASERVICE-GETTEMA-${tema.id}`).pipe(take(1), takeUntil(this.destroy$)).subscribe();
      this.localCacheService.expire(`MATERIADATASERVICE-GETMATERIA-${materiaId}`).pipe(take(1), takeUntil(this.destroy$)).subscribe();

      return this.temasDataService.updateTema(tema, materiaId).pipe(
        take(1),
        takeUntil(this.destroy$))
        .subscribe(() => {
          setTimeout(() => {
            this.getTema(tema.id);
          }, 500);
        });
    }, 500);
  }

  mostrarTema(data: any): Observable<any> {
    return this.temasDataService.mostrarTema(data);
  }

  deleteTema(data: any): Observable<any> {
    this.localCacheService.expire(`TEMASDATASERVICE-GETTEMA-${data.id}`).pipe(take(1), takeUntil(this.destroy$)).subscribe();
    return this.temasDataService.deleteTema(data);
  }

  descargaProgramacionAula(data: any) {
    this.temasDataService.descargaProgramacionAula(data).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        () => {
          // empty
        },
        error => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: error?.status,
              errorMessage: error?.statusText,
              popupId: 'dialog-programacion-aula',
              popupName: 'popup_programación_aula'
            }
          };
          this.gtagService.event(info);
        }
      );
  }

  guiaDidactica(data: any) {
    this.temasDataService.guiaDidactica(data).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => this.getTema(data.temaId));
  }

  desasignarContenido(data: any): Observable<any> {
    return this.temasDataService.desasignarContenido(data);
  }

  duplicarTema(data: any): Observable<any> {
    return this.temasDataService.duplicarTema(data);
  }

  moverTema(data: any): Observable<any> {
    return this.temasDataService.moverTema(data);
  }

  copiarTema(data: any): Observable<any> {
    return this.temasDataService.copiarTema(data);
  }

  asignarUnidad(data: any): Observable<any> {
    return this.temasDataService.asignarUnidad(data);
  }

  crearEnlace(data: any): Observable<any> {
    return this.temasDataService.crearEnlace(data);
  }

  compartirTema(data: any): Observable<any> {
    return this.temasDataService.compartirTema(data);
  }

  compartirCon(data: any): Observable<any> {
    return this.temasDataService.compartirCon(data);
  }

  compartirModulo(data: any): Observable<any> {
    return this.temasDataService.compartirModulo(data);
  }

  crearModuloDesdePlantilla(data: any): Observable<any> {
    return this.temasDataService.crearModuloDesdePlantilla(data);
  }

  crearModuloRecursoEnlace(data: any): Observable<any> {
    return this.temasDataService.crearModuloRecursoEnlace(data);
  }

  crearModuloRecursoDocumento(data: any): Observable<any> {
    return this.temasDataService.crearModuloRecursoDocumento(data);
  }

  crearModuloRecursoVideo(data: any): Observable<any> {
    return this.temasDataService.crearModuloRecursoVideo(data);
  }

  crearModuloRecursoImagen(data: any): Observable<any> {
    return this.temasDataService.crearModuloRecursoImagen(data);
  }

  crearModuloRecursoAudio(data: any): Observable<any> {
    return this.temasDataService.crearModuloRecursoAudio(data);
  }

  crearModuloDocumento(data: any): Observable<any> {
    return this.temasDataService.crearModuloDocumento(data);
  }

  crearModuloVideo(data: any): Observable<any> {
    return this.temasDataService.crearModuloVideo(data);
  }

  crearModuloImagen(data: any): Observable<any> {
    return this.temasDataService.crearModuloImagen(data);
  }

  crearModuloAudio(data: any): Observable<any> {
    return this.temasDataService.crearModuloAudio(data);
  }

  getModuloDesdePlantilla(idModulo: any): Observable<any> {
    return this.temasDataService.getModuloDesdePlantilla(idModulo);
  }

  duplicarModuloConEditorReferenciado(idModulo: any): Observable<any> {
    return this.temasDataService.duplicarModuloConEditorReferenciado(idModulo);
  }

  duplicarModulo(idModulo: any): Observable<any> {
    return this.temasDataService.duplicarModulo(idModulo);
  }

  adjuntarFicheroModulo(idModulo: any, fichero: any): Observable<any> {
    return this.temasDataService.adjuntarFicheroModulo(idModulo, fichero);
  }

  copiarModulo(data: any) {
    return this.temasDataService.copiarModulo(data).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire('MATERIADATASERVICE-GETASIGNATURAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.localCacheService.expire(`TEMASDATASERVICE-GETTEMA-${data.otroTemaId}`).pipe(take(1), takeUntil(this.destroy$)).subscribe();
        this.materiaManagementService.getMisMaterias();
        this.materiaManagementService.getAsignaturas();
        this.getTema(data.origenTemaId);
      },
        error => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: error?.status,
              errorMessage: error?.statusText,
              popupId: 'dialog-copiar',
              popupName: 'popup_copiar'
            }
          };
          this.gtagService.event(info);
        });
  }

  moverModulo(data: any): Observable<any> {
    this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
    this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
    this.localCacheService.expire('MATERIADATASERVICE-GETASIGNATURAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
    this.materiaManagementService.getMisMaterias();
    this.materiaManagementService.getAsignaturas();
    return this.temasDataService.moverModulo(data);
  }

  descargarIndicePdf(materiaId: string, userId: string, rol: string, titulo: string) {
    this.temasDataService.descargarIndicePdf(materiaId, userId, rol).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(data => {
        const blob = new Blob([data], { type: 'application/vnd.oasis.opendocument.text' });

        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${titulo}.odt`;
        link.click();
      },
        error => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: error?.status,
              errorMessage: error?.statusText,
              popupId: 'dialog-descargar-pdf',
              popupName: 'popup_descargar_pdf'
            }
          };
          this.gtagService.event(info);
        });
  }

  descargarContenidoPdf(temaId: string, titulo: string, materiaId: string,) {
    this.temasDataService.descargarContenidoPdf(temaId, materiaId).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(data => {
        const blob = new Blob([data], { type: 'application/pdf' });

        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${titulo}.pdf`;
        link.click();
      },
        error => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: error?.status,
              errorMessage: error?.statusText,
              popupId: 'dialog-descargar-pdf',
              popupName: 'popup_descargar_pdf'
            }
          };
          this.gtagService.event(info);
        });
  }

  descargarContenidoPdfResumen(temaId: string, titulo: string, materiaId: string,) {
    this.temasDataService.descargarContenidoPdfResumen(temaId, materiaId).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(data => {
        const blob = new Blob([data], { type: 'application/pdf' });

        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = `Resumen de ${titulo}.pdf`;
        link.click();
      },
        error => {
          const info = {
            event: 'popup_error',
            popupError: {
              errorId: error?.status,
              errorMessage: error?.statusText,
              popupId: 'dialog-descargar-pdf',
              popupName: 'popup_descargar_pdf'
            }
          };
          this.gtagService.event(info);
        });
  }

  getAnotaciones() {
    this.temasDataService.getAnotaciones().pipe(
      takeUntil(this.destroy$)
    ).subscribe(response => {
      this._anotaciones.next(response);
    });
  }

  addAnotaciones(data: any) {
    this.temasDataService.addAnotaciones(data).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getAnotaciones();
      });
  }

  deleteAnotaciones(data: any) {
    this.temasDataService.deleteAnotaciones(data).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getAnotaciones();
      });
  }

  getHighlights(temaId: string) {
    this.temasDataService.getHighlights(temaId).pipe(
      takeUntil(this.destroy$)
    ).subscribe(response => {
      this._highlights.next(response);
    });
  }

  addHighlights(temaId, moduloId, data: any) {
    this.temasDataService.addHighlights(temaId, moduloId, data).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(() => {
        this.getHighlights(temaId);
      });
  }

  deleteHighlights(temaId, moduloId): Observable<any> {
    return this.temasDataService.deleteHighlights(temaId, moduloId);
  }


  private temaNext(tema: Tema) {
    if (tema) {
      tema = this.editionModeService.recalculoIndicesOrden(tema);
      tema.indiceSecciones = this.generaIndiceSecciones(tema);
      this.obtenerSeccionesConRecursos(tema.modulosOrganizacion);
      this.seccionesConRecursos = this.seccionesConRecursos.filter(seccion => {
        if (seccion.modulosOrganizacion.length > 0) {
          return seccion;
        }
      });
      this.marcarSeccionesConRecursos(tema.indiceSecciones, this.seccionesConRecursos);
    }


    this._tema.next(tema);
  }


  private recursoNext(modulo: ModulosOrganizacion) {
    this._recurso.next(modulo);
  }

  private otrosTemasNext(temas: Tema[]) {
    let temasObj = [];
    if (temas) {
      temasObj = temas.map(tema => (new Tema(tema)));
    }
    this._otrosTemas.next(temasObj);
  }

  generaIndiceSecciones(tema: Tema) {

    this.indiceSeccion = [];

    this.iterateModulosorganizacion(tema.modulosOrganizacion, [0, 0, 0, 0, 0], 0, 0);
    return this.indiceSeccion;

    // return this.indiceSeccion.sort((a, b) => this.compare(a.indice, b.indice));
  }

  private iterateModulosorganizacion(modulosOrganizacion, indiceSeccion, nivel, index) {
    if (!modulosOrganizacion || modulosOrganizacion.length < 1) return;
    const indice = {
      id: modulosOrganizacion[index].id,
      tipo: 'seccion',
      indice: modulosOrganizacion[index].indiceSeccion,
      indiceVirtual: modulosOrganizacion[index].indiceSeccionVirtual,
      titulo: modulosOrganizacion[index].propiedades.titulo_html,
      visible: modulosOrganizacion[index].propiedades.visible
    };
    if (modulosOrganizacion[index].comunes.tipoModulo.idValor === 1) {
      if (modulosOrganizacion[index].propiedades.visible === 'Alumnos') {
        this.indiceSeccion.push(indice);
      }
    }
    // Profundizamos en los modulos
    if (modulosOrganizacion[index].modulosOrganizacion) {
      nivel++;
      this.iterateModulosorganizacion(modulosOrganizacion[index].modulosOrganizacion, indiceSeccion, nivel, 0);
      indiceSeccion[nivel] = 0;
      nivel--;
    }
    // Recorremos los modulos
    if ((modulosOrganizacion.length - 1) > index) {
      index++;
      this.iterateModulosorganizacion(modulosOrganizacion, indiceSeccion, nivel, index);
    }
  }

  indicesVirtuales(temas: any[]): any[] {
    temas.forEach(tema => {
      tema.indiceSeccionesVirtuales = tema.indiceSecciones.filter(indiceSeccion =>
        indiceSeccion.visible === 'Alumnos'
      );

      tema.indiceSeccionesVirtuales.forEach((indiceSeccion, index) => {
        indiceSeccion.indiceOriginal = indiceSeccion.indice;
        if (index === 0) {
          indiceSeccion.indice = {
            seccion: 1,
            subseccion: 0,
            subsubseccion: 0,
            subsubsubseccion: 0 // Nuevo nivel: subsubsubseccion
          };
        } else {
          const prevIndice = tema.indiceSeccionesVirtuales[index - 1].indice;

          if (indiceSeccion.indice.seccion !== prevIndice.seccion) {
            indiceSeccion.indice = {
              seccion: prevIndice.seccion + 1,
              subseccion: 0,
              subsubseccion: 0,
              subsubsubseccion: 0 // Reinicia subsubsubseccion al cambiar de sección
            };
          } else if (indiceSeccion.indice.subseccion !== prevIndice.subseccion) {
            indiceSeccion.indice = {
              seccion: prevIndice.seccion,
              subseccion: prevIndice.subseccion + 1,
              subsubseccion: 0,
              subsubsubseccion: 0 // Reinicia subsubsubseccion al cambiar de subsección
            };
          } else if (indiceSeccion.indice.subsubseccion !== prevIndice.subsubseccion) {
            indiceSeccion.indice = {
              seccion: prevIndice.seccion,
              subseccion: prevIndice.subseccion,
              subsubseccion: prevIndice.subsubseccion + 1,
              subsubsubseccion: 0 // Reinicia subsubsubseccion al cambiar de subsubsección
            };
          } else {
            indiceSeccion.indice = {
              seccion: prevIndice.seccion,
              subseccion: prevIndice.subseccion,
              subsubseccion: prevIndice.subsubseccion,
              subsubsubseccion: prevIndice.subsubsubseccion + 1 // Incrementa subsubsubseccion
            };
          }
        }
      });
    });
    return temas;
  }

  compare(a, b) {
    if (parseInt(`${a.seccion}`, 10) < parseInt(`${b.seccion}`, 10)) {
      return -1;
    } else if (parseInt(`${a.seccion}`, 10) > parseInt(`${b.seccion}`, 10)) {
      return 1;
    } else {
      if (parseInt(`${a.subseccion}`, 10) < parseInt(`${b.subseccion}`, 10)) {
        return -1;
      } else if (parseInt(`${a.subseccion}`, 10) > parseInt(`${b.subseccion}`, 10)) {
        return 1;
      } else {
        if (parseInt(`${a.subsubseccion}`, 10) < parseInt(`${b.subsubseccion}`, 10)) {
          return -1;
        } else if (parseInt(`${a.subsubseccion}`, 10) > parseInt(`${b.subsubseccion}`, 10)) {
          return 1;
        } else {
          return 0;
        }
      }
    }

  }

  listaRecusos() {
    const secciones = [];
    this.tema.modulosOrganizacion.forEach(modulo => {
      if (modulo.comunes.tipoModulo.idValor + '' === '1') {
        this.seleccionaRecursos('7', modulo);
        secciones.push(
          {
            ...modulo,
            recursos: [...this.tmpListaRercursos],
            checked: false,
            indeterminate: false
          }
        );
        this.tmpListaRercursos = [];
      }
    });

    return secciones;
  }

  private seleccionaRecursos(id, modulo) {
    let i, currentModulo, result;

    if ((id === modulo.comunes.tipoModulo.idValor + '' && modulo.propiedades.modoVisualizacion?.modo !== 'Inline') ||
      11 === modulo.comunes.tipoModulo.idValor) {
      if (11 === modulo.comunes.tipoModulo.idValor && modulo.modulosOrganizacion.length > 0) {
        return modulo.modulosOrganizacion[0];
      } else {
        return modulo;
      }
    } else {

      // Use a for loop instead of forEach to avoid nested functions
      // Otherwise "return" will not work properly
      if (modulo.modulosOrganizacion?.length) {
        for (i = 0; i < modulo.modulosOrganizacion.length; i += 1) {
          currentModulo = modulo.modulosOrganizacion[i];

          // Search in the current child
          result = this.seleccionaRecursos(id, currentModulo);

          // Return the result if the node has been found
          if (result !== false) {
            this.tmpListaRercursos.push(result);
          }
        }
      }

      // The node has not been found and we have no more options
      return false;
    }
  }

  setTemaEnEdicion(idTema: string) {
    return this.temasDataService.setTemaEnEdicion(idTema);
  }

  unsetTemaEnEdicion(idTema: string) {
    return this.temasDataService.unsetTemaEnEdicion(idTema);
  }

  syncUnsetTemaEnEdicion(idTema: string, jwt: string) {
    this.temasDataService.syncUnsetTemaEnEdicion(idTema, jwt);
  }

  public getTareasPorSeccion(idTema: string, idSeccion: string): Observable<any> {
    return this.temasDataService.getTareasPorSeccion(idTema, idSeccion);
  }


  // Función principal para obtener secciones con recursos
  private obtenerSeccionesConRecursos(modulos: any[]) {
    this.seccionesConRecursos = [];
    if (modulos) {
      modulos.forEach(modulo => {
        if (modulo.comunes && modulo.comunes.tipoModulo && modulo.comunes.tipoModulo.valor === 'seccion') {
          // Crear una nueva sección y obtener sus recursos directos
          const seccion = { ...modulo };
          seccion.modulosOrganizacion = [];
          this.obtenerRecursos(modulo.modulosOrganizacion, seccion.modulosOrganizacion);
          // Añadir la sección a la lista solo si no está vacía
          if (seccion.modulosOrganizacion.length > 0) {
            this.seccionesConRecursos.push(seccion);
          }
          // Obtener subsecciones y sus recursos recursivamente
          this.obtenerSubseccionesConRecursos(modulo.modulosOrganizacion);
        }
      });
    }
  }

  // Función para obtener subsecciones con recursos
  private obtenerSubseccionesConRecursos(modulos: any[]) {
    if (modulos) {
      modulos.forEach(modulo => {
        if (modulo.comunes && modulo.comunes.tipoModulo && modulo.comunes.tipoModulo.valor === 'seccion') {
          // Crear una nueva sección y obtener sus recursos directos
          const seccion = { ...modulo };
          seccion.modulosOrganizacion = [];
          this.obtenerRecursos(modulo.modulosOrganizacion, seccion.modulosOrganizacion);
          // Añadir la sección a la lista solo si no está vacía
          if (seccion.modulosOrganizacion.length > 0) {
            this.seccionesConRecursos.push(seccion);
          }
          // Obtener subsecciones y sus recursos recursivamente
          this.obtenerSubseccionesConRecursos(modulo.modulosOrganizacion);
        }
      });
    }
  }

  // Función para obtener recursos directos de un nivel
  private obtenerRecursos(modulos: any[], recursos: any[]) {
    if (modulos) {
      modulos.forEach(modulo => {
        if (modulo.comunes && modulo.comunes.tipoModulo && modulo.comunes.tipoModulo.valor === 'recurso' && modulo.propiedades.modoVisualizacion?.modo !== 'Inline') {
          recursos.push(modulo);
        }
        if (modulo.comunes.tipoModulo.idValor === ID_TIPO_APRENDIZAJE && modulo.modulosOrganizacion.length > 0) {
          modulo.modulosOrganizacion[0].idParent = modulo.id;
          recursos.push(modulo.modulosOrganizacion[0]);
        }
      });
    }
  }

  tieneRecurso(modulo) {
    if (modulo.comunes.tipoModulo.valor === 'recurso') {
      return true;
    }
    if (modulo.modulosOrganizacion && modulo.modulosOrganizacion.length > 0) {
      return modulo.modulosOrganizacion.some(subModulo => this.tieneRecurso(subModulo));
    }
    return false;
  }

  marcarSeccionesConRecursos(indiceSecciones, seccionesConRecursos) {
    const seccionesConRecursosIds = new Set();
    seccionesConRecursos.forEach(seccion => {
      if (this.tieneRecurso(seccion)) {
        seccionesConRecursosIds.add(seccion.id);
      }
    });

    indiceSecciones.forEach(seccion => {
      seccion.tieneRecursos = seccionesConRecursosIds.has(seccion.id);
    });

    return indiceSecciones;
  }


  saveScrollPosition(position?) {
    if (this.componentVisible === 1) {
      this.scrollPosition = position ? position : window.scrollY;
    } else {
      const el = document.getElementById(position ? position : this.editionModeService.moduloSeleccionado.id);
      const scrollContainer = document.querySelector('[scrollHorizontal]');
      if (el && scrollContainer) {
        this.scrollPosition = {
          offset: el.getBoundingClientRect().left,
          scrollLeft: scrollContainer.scrollLeft
        };

      }
    }

  }

  restoreScrollPosition() {
    const animacionDefault = document.documentElement.style.scrollBehavior;
    document.documentElement.style.scrollBehavior = 'auto';
    if (this.componentVisible === 1) {
      window.scroll({ top: this.scrollPosition, behavior: 'auto' });
    } else {
      const scrollContainer = document.querySelector('[scrollHorizontal]');
      if (scrollContainer) {
        const newScrollLeft = this.scrollPosition?.scrollLeft - this.scrollPosition?.offset;
        scrollContainer.scroll({
          left: newScrollLeft,
          behavior: 'auto'
        });
      }

    }
    document.documentElement.style.scrollBehavior = animacionDefault;
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
