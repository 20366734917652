/* eslint-disable no-bitwise */
/* eslint-disable max-len */
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TemasManagementService } from './data/temas/temas-management.service';

@Injectable({
  providedIn: 'root'
})
export class HighlightsModeService implements OnDestroy {

  rNG = null;
  target = null;
  innerHTMLSubrayado = null;
  contenidoHtml = null;
  removeMode = false;

  enable$: Observable<boolean>;
  dialogOpen$: Observable<boolean>;
  selected$: Observable<boolean>;
  position$: Observable<string>;
  options$: Observable<[any]>;

  highlightsToDelete = [];

  color = '#facc5c';

  moduloSeleccionado: any = {};
  headerData: any = {};
  type = '';

  index = 0;
  moduloParentAdd = '';
  parent = null;
  parentInjector = null;

  fromDuplicarUnidad = false;

  ocultos = [];
  acumuladoNegativo = 0;

  private _enable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _dialogOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _selected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _position: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private temasManagementService: TemasManagementService
    // private notificationService: NotificationService,
    // private authManagementService: AuthManagementService,
    // private translateService: TranslateService,
  ) {
    this.enable$ = this._enable.asObservable();
    this.dialogOpen$ = this._dialogOpen.asObservable();
    this.selected$ = this._selected.asObservable();
    this.position$ = this._position.asObservable();


  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get enable() {
    return this._enable.getValue();
  }


  get dialogOpen() {
    return this._dialogOpen.getValue();
  }

  set dialogOpen(dialogOpen: boolean) {
    this._dialogOpen.next(dialogOpen);
  }

  get selected() {
    return this._selected.getValue();
  }

  set selected(selected: boolean) {
    this._selected.next(selected);
  }

  get position() {
    return this._position.getValue();
  }

  set position(position: string) {
    this._position.next(position);
  }



  private gSel() {
    const d = document as any;
    if (d.selection)
      return d.selection.type === 'Text' ? d.selection : null;
    if (window.getSelection)
      return window.getSelection();
    return null;
  }

  private cRng() {
    const sel = this.gSel();
    if (sel) {
      if (sel.createRange) return sel.createRange();
      if (sel.rangeCount && sel.getRangeAt) return sel.getRangeAt(0);
    }
    return null;
  }

  sel(rng) {
    if (rng.select) rng.select();
    else {
      const s = this.gSel();
      if (s.removeAllRanges && s.addRange) {
        s.removeAllRanges();
        s.addRange(rng);
      }
    }
  }

  r() {
    if (this.rNG) this.sel(this.rNG);
  }

  s() {
    this.rNG = this.cRng();
  }

  removeRNG() {
    this.rNG = null;
  }


  deleteMassive() {
    this.highlightsToDelete.forEach(hl => {
      this.temasManagementService.deleteHighlights(hl.temaId, hl.moduloId).pipe(
        take(1),
        takeUntil(this.destroy$))
        .subscribe();
    });
  }


  /**
   * Al iniciar el modo edición, se desactivan todos los enlaces de la pantalla.
   * Al desactivar el modo edición, se activan todos los enlaces de nuevo.
   */
  private handleLinks() {
    const links = document.querySelectorAll('#tema a');
    if (links.length) {
      links.forEach(link => {
        if (link) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.enable ? this.disableLink(link) : this.enableLink(link);
        }
      });
    }
  }

  closeWiris() {
    const _win = window as any;
    _win.WirisPlugin?.currentInstance?.core?.modalDialog?.close();
  }

  /**
   * Desactiva los links de la pantalla. 
   * Asi se evita puntos de fuga.
   *  
   * @param link: Element
   */
  disableLink(link) {
    link.parentElement.classList.add('disable-link');
  }

  /**
   * Activa todos los links de la pantalla.
   * @param link: Element
   */
  enableLink(link) {
    if (link.parentElement.classList.contains('disable-link')) {
      link.parentElement.classList.remove('disable-link');
    }
  }

  generateUUID() { // Public Domain/MIT
    let d = new Date().getTime(); // Timestamp
    let d2 = ((typeof performance !== 'undefined') &&
      performance.now && (performance.now() * 1000)) || 0; // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16; // random number between 0 and 16
      if (d > 0) { // Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else { // Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }


}
