/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ChangeDetectionStrategy, Input, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, filter, take } from 'rxjs/operators';
import { TemasManagementService } from '../../../services/data/temas/temas-management.service';
import { MateriaManagementService } from '../../../services/data/materia/materia-management.service';
import { AuthManagementService } from '../../../core/auth/auth-management.service';
import { GtagService } from '../../../services/gtag/gtag.service';
import { LtiManagementService } from '../../../services/data/lti/lti-management.service';
import { GenericComponent } from '../dialogs/generic/generic.component';
import { ModulosOrganizacion } from '../../models/tema/modulo.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'aula-planeta-reset-modulo',
  templateUrl: './reset-modulo.component.html',
  styleUrls: ['./reset-modulo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ResetModuloComponent implements OnDestroy, OnChanges {
  isMouseOver = false;
  @Input() show = false;
  @Input() modulo: ModulosOrganizacion;

  private destroy$: Subject<void> = new Subject<void>();

  private _subrayados: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  subrayados$ = this._subrayados.asObservable();

  constructor(
    public authManagementService: AuthManagementService,
    public dialog: MatDialog,
    public cd: ChangeDetectorRef,
    public ltiManagementService: LtiManagementService,
    public materiaManagementService: MateriaManagementService,
    public temasManagementService: TemasManagementService,
    private gtagService: GtagService,
    private translateService: TranslateService,
  ) {


    this.temasManagementService.highlights$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.filterAnnotationes();
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterAnnotationes();
  }

  filterAnnotationes() {
    const misSubrayados = this.temasManagementService.highlights.find(hl => hl.moduloId === this.modulo?.id);
    if (misSubrayados) {
      this.show = true;
    }
    this._subrayados.next(misSubrayados);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openDialog(event: PointerEvent) {
    event.preventDefault(); event.stopPropagation();
    const dialogRef = this.dialog.open(GenericComponent, {
      data: {
        titulo: this.translateService.instant('dialogs.resetModulo.titulo'),
        icon: 'history',
        contenido: this.translateService.instant('dialogs.resetModulo.contenido'),
        cerrar: 'cerrar',
        color: 'standard',
        close: true, // muestra el aspa de cerrado,
        actions: [
          {
            key: 'generico.cancelar',
            type: 'secondary'
          },
          {
            key: 'generico.aceptar',
            type: 'primary',
            onClose: true
          }
        ]
      },
      restoreFocus: true
    });

    dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(result => {
      if (result) {
        this.temasManagementService.deleteHighlights(this.temasManagementService.tema.id, this.modulo.id).pipe(
          take(1),
          takeUntil(this.destroy$))
          .subscribe(() => {
            window.location.reload();
          });
      }
    });

    const info = {
      event: 'popup',
      popup: {
        action: 'add_annotation',
        pageId: event?.view?.location.hash,
        popupId: 'annotations',
        popupName: 'popup_annotations'
      }
    };
    this.gtagService.event(info);
  }

}
