import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { EnvService } from '../../../../services/env.service';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { EditRecursoPlantillaComponent } from '../edit-recurso-plantilla/edit-recurso-plantilla.component';
import { EditionModeService } from '../../../../services/edition-mode.service';
import { TemasManagementService } from '../../../../services/data/temas/temas-management.service';
import * as DecoupledDocumentEditor from '../../../../../assets/js/ck-editor-math-type/ckeditor';

@Component({
  selector: 'aula-planeta-editar-recurso',
  templateUrl: 'editar-recurso.component.html',
  styleUrls: ['./editar-recurso.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditarRecursoComponent implements OnInit, AfterViewChecked {
  @ViewChild('editorQAlumno', { static: false }) editorQAlumno: any;
  @ViewChild('editorQProfesor', { static: false }) editorQProfesor: any;
  editor = DecoupledDocumentEditor;
  isDisabled = true;
  fichaAlumno = '';
  fichaProfesor = '';

  config = {
    language: 'es',
    fontSize: { options: [9, 11, 12, 13, 'default', 17, 19, 21] },
    toolbar: ['heading', '|', 'bold', 'italic', 'fontSize',
      'fontFamily', 'fontColor', 'underline', 'alignment', 'highlight',
      'fontBackgroundColor', 'numberedList', 'link', 'insertTable', 'bulletedList',
      'MathType', 'strikethrough', 'indent', 'outdent', 'undo',
      'redo', 'exportPdf', 'exportWord'],
    link: {
      addTargetToExternalLinks: true
    },
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' }
      ]
    }
  };

  recursoForm: UntypedFormGroup;
  @ViewChild('selectorFiltro') selectorFiltro: MatSelect;
  filtroSelector = 'ver todo';
  removable = true;
  metadata = [];
  metaInput = '';
  displayedColumns: string[] = ['dragDrop', 'titulo', 'fichero', 'visibilidad', 'acciones'];

  plantillaData$: Subject<void> = new Subject<void>();

  showAttachedFilesModal = false;
  file;
  fileForm: UntypedFormGroup;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(

    
    private temasManagementService: TemasManagementService,
    public editionModeService: EditionModeService,
    private envService: EnvService,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditarRecursoComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) { }

  ngAfterViewChecked(): void {
    this.ensureToolbarVisibility(this.editorQAlumno);
    this.ensureToolbarVisibility(this.editorQProfesor);
  }

  ensureToolbarVisibility(editorViewChild) {
    if (editorViewChild && editorViewChild.instance) {
      const toolbarElement = editorViewChild.instance.ui.view.toolbar.element;
      if (toolbarElement) {
        toolbarElement.style.display = '';
      }
    }
  }

  ngOnInit() {

    this.editionModeService.enable$
    .pipe(takeUntil(this.destroy$))
    .subscribe(enable => {
        if (!enable) {
        this.dialogRef.close();
        return;
      }

    this.recursoForm = this.fb.group({
      name: [this.data.propiedades.titulo_plano, Validators.required],
      author: [this.data.propiedades.autor, Validators.required],
      description: [this.data.propiedades.descripcion_html, Validators.required],
      fichaAlumno: [this.data.propiedades.ficha_alumno_html],
      fichaProfesor: [this.data.propiedades.ficha_profesor_html],
      metas: [this.data.metadatos]
    });

    this.showAttachedFilesModal = (this.data.propiedades.adjuntos && this.data.propiedades.adjuntos.length) > 0 ? false : true;

    this.fileForm = this.fb.group({
      file: [null, Validators.required],
      title: ['']
    });

    this.isDisabled = false;

    const info = {
      event: 'popup',
      popup: {
        action: 'editar',
        popupId: 'dialog-editar-recurso',
        popupName: 'popup_editar_recurso'
      }
    };
    this.gtagService.event(info);
  })
}


  onClose() {
    const ficha_alumno_html = this.recursoForm.value.fichaAlumno;
    const ficha_profesor_html = this.recursoForm.value.fichaProfesor;

    const response = {
      titulo_plano: this.temasManagementService.plantillaData ? this.temasManagementService.plantillaData.propiedades.titulo_plano : this.recursoForm.value.name,
      titulo_html: this.temasManagementService.plantillaData ? this.temasManagementService.plantillaData.propiedades.titulo_html : this.recursoForm.value.name,
      descripcion_html: this.temasManagementService.plantillaData ? this.temasManagementService.plantillaData.propiedades.descripcion_html : this.recursoForm.value.description,
      autor: this.recursoForm.value.author,
      adjuntos: this.data.propiedades.adjuntos,
      ficha_profesor_html,
      ficha_alumno_html
    };
    this.dialogRef.close(response);
    this.temasManagementService.plantillaData = null;
  }

  add(metaValue): void {
    const value = (metaValue || '').trim();

    if (value) {
      this.data.metadatos.push({ name: value, type: 'profesor' });
      this.recursoForm.patchValue({ metas: this.data.metadatos });
    }
  }

  remove(item): void {
    const index = this.data.metadatos.indexOf(item);

    if (index >= 0) {
      if (!this.data.metadatos) {
        this.data.metadatos = [];
      }
      this.data.metadatos.splice(index, 1);
      this.recursoForm.patchValue({ metas: this.data.metadatos });
    }
  }

  onListDrop(event: CdkDragDrop<string[]>) {
    if (event.container === event.previousContainer) {
      const currentValue = [...this.data.propiedades.adjuntos];
      moveItemInArray(currentValue, event.previousIndex, event.currentIndex);
      this.data.propiedades.adjuntos = currentValue;
    }
  }

  onDrop(e) {
    this.file = (e as HTMLInputElement);

    const reader = new FileReader();

    reader.readAsDataURL(this.file);

    reader.onloadend = () => {
      this.fileForm.get('file').setValue(reader.result as string);
      this.fileForm.get('title').setValue(this.file.name);
      this.fileForm.markAsDirty();
      this.fileForm.updateValueAndValidity();
      this.cd.markForCheck();
    };

    const url = URL.createObjectURL(this.file);
    this.fileForm.patchValue({
      file: url
    });
  }

  removeFileRow(index) {
    const position = this.data.propiedades.adjuntos.indexOf(index);
    if (position > -1) {
      this.data.propiedades.adjuntos = this.data.propiedades.adjuntos.filter((item, index) => index !== position);
      this.showAttachedFilesModal = this.data.propiedades.adjuntos.length > 0 ? false : true;
    }
  }

  addFile() {
    const tmp$: Subject<void> = new Subject<void>();
    this.temasManagementService.adjuntarFicheroModulo(this.data.id, {
      fichero: {
        nombreFichero: this.fileForm.get('title').value,
        contenidoFichero: this.fileForm.get('file').value.split('base64,')[1],
        encodingType: 0
      }
    }).pipe(
      take(1),
      takeUntil(tmp$))
      .subscribe(adjunto => {
        tmp$.next();
        tmp$.complete();
        if (!this.data.propiedades.adjuntos) {
          this.data.propiedades.adjuntos = [adjunto];
        } else {
          this.data.propiedades.adjuntos.push(adjunto);
        }
        this.data.propiedades.adjuntos = [...this.data.propiedades.adjuntos];
        this.cd.markForCheck();
      });

    this.file = null;
    this.fileForm.patchValue({
      file: '',
      title: ''
    });
    this.showAttachedFilesModal = false;
    this.cd.detectChanges();
  }

  deleteFile() {
    this.fileForm.patchValue({
      file: null
    });

    this.fileForm.get('file').updateValueAndValidity();
  }

  editarPlantilla(isPlantilla) {
    if (isPlantilla) {
      const win = window as any;
      win.temasManagementService = this.temasManagementService;
      win.idModulo = this.data.id;
      win.editRecursoPlantillaComponent = this;

      let actividad: string = this.data.propiedades.actividad;
      actividad = actividad.replace('Principal', 'teditor');
      const url = `${actividad}?out_url=${environment.api}/plantilla/actualizar/${this.data.id}/editar`;

      win.open(this.envService.rootContentNoCDN + url);

      win.onEditorUpdated = function (action) {
        if (action === 'save') {
          const tmp$: Subject<void> = new Subject<void>();
          win.temasManagementService.getModuloDesdePlantilla(win.idModulo).pipe(
            take(1),
            takeUntil(tmp$))
            .subscribe(nuevoModulo => {
              tmp$.next();
              tmp$.complete();
              this.temasManagementService.plantillaData = nuevoModulo;
            });
        }
        if (action === 'cancel') {
          this.onCancel(null);
        }
      };
    } else {
      let player = this.data.propiedades?.tipoActividad?.valor?.player;
      player = player.split('fs1://')[1];
      player = player.split('.zip')[0];
      let url = this.data.propiedades?.tipoActividad?.valor?.editor;
      const idModulo = this.data.id;
      url = url.split('fs1://')[1];
      url = `${environment.rootContentNoCDN}/Contenido/` + url;
      url = url.split('.zip')[0];
      url = url + `/Editor.html?data_path=/Contenido/Modulos/${idModulo}/data&plantilla=1&out_url=${environment.api}/plantilla/actualizar/${idModulo}/editar&preview_path=/Contenido/${player}`;

      const dialogRef = this.dialog.open(EditRecursoPlantillaComponent, {
        data: {
          url,
          idModulo: idModulo,
          color: 'standard'
        },
        restoreFocus: true
      });
      dialogRef.afterClosed().pipe(take(1)).subscribe(nuevoModulo => {
        if (nuevoModulo) {
          this.temasManagementService.plantillaData = nuevoModulo;
          this.recursoForm.patchValue({
            name: nuevoModulo.titulo_plano,
            description: nuevoModulo.descripcion_html
          });
          this.cd.detectChanges();
        }
      });
    }
  }

  public onChange(tipo, { editor }) {
    const data = editor.getData();
    if (tipo === 'Alumno') {
      this.recursoForm.patchValue({ fichaAlumno: data });
    } else {
      this.recursoForm.patchValue({ fichaProfesor: data });
    }
  }

}
