import { AbstractControl, ValidationErrors } from '@angular/forms';

export function validarFechaHora(control: AbstractControl): ValidationErrors | null {
  const inicio = control.get('inicio')?.value;
  const horaInicio = control.get('horaInicio')?.value; 

  if (!inicio || !horaInicio) {
    return null; 
  }

  const fechaHoraInicio = new Date(inicio);
  const [hours, minutes] = horaInicio.split(':').map((v: string) => parseInt(v, 10));
  fechaHoraInicio.setHours(hours, minutes, 0, 0);

  const fechaHoraActual = new Date();
  fechaHoraActual.setMinutes(fechaHoraActual.getMinutes() - 1);

 
  if (fechaHoraInicio < fechaHoraActual) {
    return { fechaHoraInvalida: true };
  }

  return null; 
}
