<div id="dialog-asignar-tarea" class="standard d-flex flex-column">

    <h1 mat-dialog-title class="custom-text font-weight-bold">{{ 'dialogs.asignar-tarea.title' | translate }}</h1>

    <div mat-dialog-content class="mat-dialog-content">

        <mat-horizontal-stepper [linear]="true" #stepper class="default-stepper" (selectionChange)="onStepperChange($event)">
            <ng-template class="mateo" matStepperIcon="edit">
                <mat-icon>check</mat-icon>
            </ng-template>

            <mat-step [stepControl]="firstStep" label="{{ 'dialogs.asignar-tarea.step1' | translate}}">
                <h2 class="custom-text my-4">{{ 'dialogs.asignar-tarea.step1.intro' | translate }}</h2>

                <div class="overflow-auto data-table-container fluid-content">
                    <form [formGroup]="firstStep">
                        <mat-accordion class="default-list" role="list">
                            <mat-expansion-panel *ngFor="let seccion of data.secciones; let i=index" [togglePosition]="'after'" class="mat-elevation-z0 my-0 ps-0" role="listitem">
                                <!-- <ng-container *ngIf="seccion.comunes.tipoModulo === '1'"> -->
                                <mat-expansion-panel-header class="default-list__row px-0 pe-4">
                                    <mat-panel-title [id]="'seccion_' + seccion.id" class="d-flex align-items-center justify-content-between w-100 me-0">
                                        <div class="d-flex align-items-center ">
                                            <mat-checkbox class="mb-0 pe-3" (change)="masterToggle(seccion)" (click)="$event.stopPropagation()" (keyup.space)="$event.stopPropagation(); masterToggle(seccion) " [aria-label]="checkboxLabel(seccion)" [checked]="isAllSeccionRecursosSelected(seccion)"
                                                [indeterminate]="isSomeSeccionRecursosSelected(seccion) && !isAllSeccionRecursosSelected(seccion)">
                                            </mat-checkbox>

                                            <h3 class="title-container mt-1" [ngClass]="colorHelper.getColor(data.materia?.color)">
                                                <span [innerHTML]="((i + 1)+'. '+ seccion.propiedades.titulo_html) | safe:'html'" class="custom-text title-seccion"></span>
                                            </h3>
                                        </div>

                                        <div class="d-flex align-items-center justify-content-end me-4">
                                            <!-- <mat-slide-toggle labelPosition="before" class="example-margin" (click)="$event.stopPropagation();">
                            Incluir contenidos de la sección
                        </mat-slide-toggle> -->
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <!-- </ng-container> -->

                                <ng-template *ngIf="seccion.recursos.length > 0" matExpansionPanelContent>
                                    <div class="d-flex flex-column recurso overflow-auto position-relative mt-4" [ngClass]="colorHelper.getColor(data.materia?.color)" [style.minHeight.px]="data.tema.modulosOrganizacion * 80">
                                        <div *ngFor="let recurso of seccion.recursos" class="d-flex">
                                            <div class="pe-3 checkbox-container">
                                                <mat-checkbox (click)="$event.stopPropagation()" (change)="singleToggle(recurso.id)" [checked]="selection.isSelected(recurso.id)" [aria-label]="checkboxLabel(recurso)"></mat-checkbox>
                                            </div>
                                            <div class="w-100 p-2 d-flex mb-3 cursor-pointer" (click)="routeWindow(recurso)" [ngClass]="(recurso.propiedades.visible === 'Profesores') ? 'only-teacher' : 'custom-bg__light'">
                                                <div class="w-85 d-flex flex-row">
                                                    <div class="img-container custom-bg__regular">
                                                        <img *ngIf="recurso.propiedades.imagen_previa !== null || recurso.propiedades.imagen_previa !== ''" sanitize-img [src]="recurso.propiedades.imagen_previa | safe:'url'" [alt]="recurso.propiedades.titulo_html">
                                                        <mat-icon class="white-icon" *ngIf="recurso.propiedades.imagen_previa === null || recurso.propiedades.imagen_previa === ''">touch_app</mat-icon>
                                                    </div>

                                                    <div class="w-40 ms-4 d-flex flex-column justify-content-center">
                                                        <!-- <span class="grey-text__light">Acción didáctica</span> -->
                                                        <h4 class="title" [innerHTML]="recurso.propiedades.titulo_html | safe:'html'"></h4>
                                                    </div>

                                                    <div class="w-60 ms-2 me-2 d-flex flex-column justify-content-center" [innerHTML]="recurso.propiedades.descripcion_html | safe:'html'"></div>
                                                </div>

                                                <div class="w-15 d-flex align-items-center justify-content-end menu-icon me-3">
                                                    <mat-icon [hidden]="recurso.propiedades.visible !== 'Profesores'" class="group-off-icon">group_off</mat-icon>
                                                    <mat-icon class="ms-2" *ngIf="recurso.propiedades.bloqueadoOffline" matTooltip="{{'generico.bloqeado-offline' | translate}}">cloud_off</mat-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>

                            </mat-expansion-panel>
                        </mat-accordion>
                    </form>
                </div>

            </mat-step>

            <mat-step [stepControl]="secondStep" label="{{ 'dialogs.asignar-tarea.step2' | translate}}">
                 <!-- <h2 class="custom-text my-4">{{ 'dialogs.asignar-tarea.step2.intro' | translate }}</h2> -->

                <div class="overflow-auto  fluid-content">
                    <form [formGroup]="secondStep">

                        <fieldset class="w-100 d-flex flex-column">

                            <legend class="custom-text my-4">{{ 'dialogs.asignar-tarea.step2.intro' | translate }}</legend>

                            <div class="d-flex">

                                <div class="w-50">
                                    <div class="w-100 form-group default-input mb-4">
                                        <label for="nombreTarea">{{ 'dialogs.asignar-tarea.step2.nombre-tarea' | translate }}</label>
                                        <input tabindex="0" id="nombreTarea" aria-labelledby="errorNombreTarea" type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.nombre-tarea' | translate)" formControlName="name" cdkFocusInitial>
                                        <mat-error id="errorNombreTarea" *ngIf="secondStep.get('name').hasError('required') && secondStep.controls['name'].dirty">
                                            {{'generico.required' | translate}}
                                        </mat-error>
                                    </div>

                                    <div class="d-flex">
                                        <div class="w-100 form-group default-textarea mb-4">
                                            <label for="comentario">{{'dialogs.asignar-tarea.step2.comentario' | translate}}</label>
                                            <textarea id="comentario" aria-labelledby="errorComentario" type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.comentario' | translate)" formControlName="comentario" maxlength="255"></textarea>
                                            <span class="text-secondary"><small>{{'('+ secondStep.controls['comentario'].value.length +'/255)'}}</small></span>
                                            <mat-error id="errorComentario" *ngIf="secondStep.get('comentario').hasError('required') && secondStep.controls['comentario'].dirty">
                                                {{'generico.required' | translate}}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="date-container w-50 ms-4">
                                    <div class="date-inicio-container d-flex">
                                        <div  class="form-group default-input mb-4 position-relative" [ngClass]="{ 'w-100': !mostrarHoras, 'w-50': mostrarHoras }">
                                            <label for="fechaInicio">{{ 'dialogs.asignar-tarea.step2.fecha-inicio' | translate }}</label>
                                            <input
                                              id="fechaInicio"
                                              type="text"
                                              class="form-control"
                                              [min]="minDate"
                                              [max]="maxDate"
                                              [matDatepicker]="pickerInicio"
                                              (click)="pickerInicio.open()"
                                              [placeholder]="('dialogs.asignar-tarea.step2.fecha-inicio' | translate)"
                                              formControlName="inicio">
                                            <mat-datepicker-toggle matSuffix [for]="pickerInicio" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerInicio startView="year" [startAt]="minDate"></mat-datepicker>
                                        </div>
                                        <div *ngIf="mostrarHoras && !isLTI() " class="default-input position-relative ms-4">
                                            <label class="invisible">hora</label>
                                            <input class="form-control"
                                              type="time"
                                              step="300"
                                              id="horaInicio"
                                              formControlName="horaInicio">
                                            <mat-error style="font-size: small;" *ngIf="secondStep.hasError('fechaHoraInvalida')">
                                                {{'dialogs.asignar-tarea.step2.fecha-hora-invalida' | translate}}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="date-fin-container d-flex">

                                        <div  class="form-group default-input mb-4 position-relative"  [ngClass]="{ 'w-100': !mostrarHoras, 'w-50': mostrarHoras }">
                                            <label for="fechaFin">{{ 'dialogs.asignar-tarea.step2.fecha-fin' | translate }}</label>
                                            <input
                                              id="fechaFin"
                                              type="text"

                                              class="form-control"
                                              [min]="secondStep.get('inicio').value"
                                              [max]="maxDate"
                                              [matDatepicker]="pickerFin"
                                              (click)="pickerFin.open()"

                                              [placeholder]="('dialogs.asignar-tarea.step2.fecha-fin' | translate)"
                                              formControlName="fin">
                                            <mat-datepicker-toggle matSuffix [for]="pickerFin" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerFin startView="year" [startAt]="secondStep.get('inicio').value"></mat-datepicker>
                                        </div>


                                        <div *ngIf="mostrarHoras"  class="default-input position-relative ms-4">
                                            <label class="invisible">hora</label>
                                            <input matInput
                                              class="form-control"
                                              type="time" step="300" id="fechaFin" value="10:00" [min]="secondStep.get('horaInicio').value"
                                              formControlName="horaFin">
                                         </div>
                                    </div>

                                    <ng-container *ngIf="!isLTI()">
                                        <mat-slide-toggle (change)="onToggleChange($event)" [checked]="mostrarHoras">
                                            Mostrar Horas
                                        </mat-slide-toggle>
                                    </ng-container>
                                </div>
                            </div>



                            <!-- <div class="w-100 d-flex">
                          <div class="form-group">
                              <mat-slide-toggle class="grey-text" disableRipple="true" formControlName="shared">{{ 'dialogs.asignar-tarea.step2.shared' | translate }}</mat-slide-toggle>
                          </div>
                      </div> -->

                        </fieldset>
                    </form>
                </div>

            </mat-step>

            <mat-step [stepControl]="thirdStep1 && thirdStep2" label="{{ 'dialogs.asignar-tarea.step3' | translate}}">
                <h2 class="custom-text my-4">{{ 'dialogs.asignar-tarea.step3.intro' | translate }}</h2>

                <div class="overflow-auto  fluid-content">
                    <!-- <form [formGroup]="thirdStep"> -->
                    <mat-tab-group class="standard" (selectedTabChange)="tabChange($event)">
                        <!-- <mat-tab label="{{ 'dialogs.asignar-tarea.step3.select-grupo' | translate}}">
                  <form [formGroup]="thirdStep1">
                      <fieldset class="w-100 d-flex flex-column my-4">
                          <div class="form-group w-35">
                              <label class="default-text">{{ 'dialogs.asignar-tarea.step3.mis-grupos' | translate }}</label>
                              <mat-select cdkFocusInitial class="default-select default-select__blue" [placeholder]="'dialogs.asignar-tarea.step3.mis-grupos' | translate" formControlName="grupo" panelClass="cdkSelect">
                                  <mat-optgroup *ngFor="let grupo of grupos" class="default-group-options">
                                      <mat-option class="default-option" *ngFor="let item of grupo" [value]="item.id">
                                          {{item.id}}
                                      </mat-option>
                                  </mat-optgroup>
                              </mat-select>
                          </div>
                      </fieldset>
                  </form>
              </mat-tab> -->

                        <mat-tab label="{{ 'dialogs.asignar-tarea.step3.select-alumnos' | translate}}">
                            <form [formGroup]="thirdStep2">
                                <div class="w-100 d-flex flex-column flex-md-row justify-content-between my-4">
                                    <div class="d-flex flex-column w-35">
                                        <div class="form-group">
                                            <label class="default-text">{{ 'dialogs.asignar-tarea.step3.grupo' | translate }}</label>
                                            <mat-select cdkFocusInitial class="default-select default-select__blue" (selectionChange)="setAlumnosListMulti()" [placeholder]="'dialogs.asignar-tarea.step3.grupo' | translate" formControlName="curso" panelClass="cdkSelect" multiple>
                                                <mat-optgroup *ngFor="let grupo of grupos" class="default-group-options">
                                                    <mat-option id="allOptions" class="default-option" *ngFor="let _grupo of grupo" [value]="_grupo">
                                                        {{_grupo.nombre}}
                                                    </mat-option>
                                                </mat-optgroup>
                                            </mat-select>
                                        </div>
                                        <div class="form-group mb-3">
                                            <mat-slide-toggle *ngIf="habilitarSeleccionManualAlumnos" class="grey-text" disableRipple="true" (change)="seleccionManualAlumnos = !seleccionManualAlumnos; enableCustomGroup = false; desasignarTodos(); setAlumnosListMulti()">{{ 'dialogs.asignar-tarea.step3.seleccion-manual' | translate }}</mat-slide-toggle>
                                        </div>
                                        <div class="form-group" *ngIf="seleccionManualAlumnos">
                                            <mat-slide-toggle class="grey-text" disableRipple="true" (change)="enableCustomGroup = !enableCustomGroup; ">{{ 'dialogs.asignar-tarea.step3.create-grupo' | translate }}</mat-slide-toggle>
                                        </div>
                                        <div *ngIf="enableCustomGroup && seleccionManualAlumnos" class="form-group default-input">
                                            <label class="default-text">{{ 'dialogs.asignar-tarea.step3.nombre-grupo-personalizado' | translate }}</label>
                                            <input type="text" class="form-control" formControlName="nombreGrupo" [placeholder]="('dialogs.asignar-tarea.step3.nombre-grupo-personalizado' | translate)">
                                        </div>
                                    </div>

                                    <div *ngIf="seleccionManualAlumnos" class="alumnos-container w-60 d-flex flex-column flex-md-row mb-4">
                                        <fieldset class="w-100 alumnos-container__list">
                                            <legend class="w-100 pb-2 mb-0 default-text">{{ 'mi-centro.gestion-contrasenas.alumnos-disponibles' | translate }}</legend>
                                            <div>
                                                <div *ngFor="let item of alumnos" class="my-3">
                                                    <mat-checkbox class="ms-3" (click)="$event.stopPropagation()" (change)="$event ? selectionDisponibles.toggle(item) : null " [checked]="selectionDisponibles.isSelected(item)" [aria-label]="item.nombreCompleto">
                                                        {{item.nombre}} {{item.apellidoUno}} {{item.apellidoDos}}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <div role="region" [attr.aria-label]="('dialogs.asignar-tarea.step3.acciones-alumnos-seleccionados' | translate)" class="alumnos-container__actions d-flex flex-row flex-md-column justify-content-center align-content-center px-4">
                                            <button mat-icon-button class="custom-text" (click)="asignarAlumnos()" attr.aria-label="{{'mi-centro.gestion-curso.asignar.alumnos' | translate}}">
                        <mat-icon>chevron_right</mat-icon>
                      </button>
                                            <button mat-icon-button class="custom-text" (click)="asignarTodos()" attr.aria-label="{{'mi-centro.gestion-curso.asignar.todos' | translate}}">
                        <mat-icon>last_page</mat-icon>
                      </button>
                                            <button mat-icon-button class="custom-text" (click)="desasignarTodos()" attr.aria-label="{{'mi-centro.gestion-curso.desasignar.todos' | translate}}">
                        <mat-icon>first_page</mat-icon>
                      </button>
                                            <button mat-icon-button class="custom-text" (click)="desasignarAlumnos()" attr.aria-label="{{'mi-centro.gestion-curso.desasignar.alumnos' | translate}}">
                        <mat-icon>chevron_left</mat-icon>
                      </button>
                                        </div>
                                        <fieldset class="w-100 alumnos-container__list">
                                            <legend class="w-100 pb-2 mb-0 default-text">{{ 'mi-centro.gestion-contrasenas.alumnos-seleccionados' | translate }}</legend>
                                            <div>
                                                <div *ngFor="let item of alumnosAsignados" class="my-3">
                                                    <mat-checkbox class="ms-3" (click)="$event.stopPropagation()" (change)="$event ? selectionAsignados.toggle(item) : null " [checked]="selectionAsignados.isSelected(item)" [aria-label]="item.nombreCompleto">
                                                        {{item.nombre}} {{item.apellidoUno}} {{item.apellidoDos}}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>

                                </div>
                            </form>
                        </mat-tab>
                    </mat-tab-group>

                    <!-- </form> -->
                </div>

            </mat-step>

        </mat-horizontal-stepper>
    </div>

    <div mat-dialog-actions class="justify-content-between">
        <button role="button" class="default-button default-button__primary" mat-dialog-close>{{ 'dialogs.create-theme.cancel' | translate }}</button>

        <div class="d-flex">
            <button role="button" class="default-button default-button__primary ms-3" [disabled]="currentStep <= 0" (click)="goBack()">{{ 'generico.volver' | translate}}</button>
            <button role="button" class="default-button default-button__secondary ms-3" *ngIf="currentStep < 1" [disabled]="!firstStep.valid" (click)="goNext()">{{ 'generico.siguiente' | translate}}</button>
            <button role="button" class="default-button default-button__secondary ms-3" *ngIf="currentStep < 2 && currentStep > 0" [disabled]="!secondStep.valid" (click)="goNext()">{{ 'generico.siguiente' | translate}}</button>
            <button role="button" class="default-button default-button__secondary ms-3" (click)="onClose()" *ngIf="currentStep >= 2" [disabled]="alumnosAsignados.length < 1">{{ 'generico.asignar' | translate }}</button>
        </div>
    </div>

</div>
