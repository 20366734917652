import { GestionAlumnoManagementService } from './../../../../services/data/gestion-alumno/gestion-alumno-management.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MatStepper } from '@angular/material/stepper';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DateHelper } from '../../../helper/date.helper';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { takeUntil, pairwise, take, filter } from 'rxjs/operators';
import { Subject, forkJoin, from, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { GenericComponent } from '../generic/generic.component';
import { EnvService } from '../../../../services/env.service';
import { ScrollService } from '../../../../core/scroll/scroll.service';
import { LtiManagementService } from '../../../../services/data/lti/lti-management.service';
import { validarFechaHora } from '../../../helper/validators.helper';


@Component({
  selector: 'aula-planeta-asignar-tarea-rapida',
  templateUrl: './asignar-tarea-rapida.component.html',
  styleUrls: ['./asignar-tarea-rapida.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DateHelper]
})
export class AsignarTareaRapidaComponent implements OnInit, AfterViewInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('stepper') private stepperSection: MatStepper;

  firstStep: UntypedFormGroup;
  secondStep: UntypedFormGroup;
  thirdStep1: UntypedFormGroup;
  thirdStep2: UntypedFormGroup;
  currentStep = 1;

  minDate = new Date(Date.now());
  maxDate = new Date(2045, 0, 1);
  selection?: SelectionModel<any>;
  indexTab = 0;
  mostrarHoras = false;

  selectionDisponibles: SelectionModel<null>;
  selectionAsignados: SelectionModel<null>;

  alumnos: any[] = [];
  alumnosTemp: any[] = [];
  alumnosAsignados: any[] = [];
  enableCustomGroup = false;
  seleccionManualAlumnos = false;
  habilitarSeleccionManualAlumnos = true;

  grupos: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    public dateHelper: DateHelper,
    public dialogRef: MatDialogRef<AsignarTareaRapidaComponent>,
    private dialog: MatDialog,
    public translateService: TranslateService,
    public envService: EnvService,
    public gestionAlumnoManagementService: GestionAlumnoManagementService,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService,
    private scrollService: ScrollService,
    private ltiManagementService: LtiManagementService
  ) {
    this.selection = new SelectionModel(true, []);
    this.selectionDisponibles = new SelectionModel<null>(true, []);
    this.selectionAsignados = new SelectionModel<null>(true, []);
  }
  ngAfterViewInit() {
    const info = {
      event: 'popup_steps',
      popupSteps: {
        popupId: 'dialog-asignar-tarea',
        popupName: 'popup_asignar_tarea',
        popupStep: this.currentStep.toString()

      }
    };
    this.gtagService.event(info);
  }

  ngOnInit() {

    this.firstStep = this.fb.group({
      recursos: [this.data.moduloId, Validators.required]
    });

    this.secondStep = this.fb.group({
      name: this.fb.control(this.data.titulo, [Validators.required]),
      inicio: this.fb.control(this.dateHelper.getOffsetLocal(this.data.periodo.fechaInicio), [Validators.required]),
      horaInicio: this.fb.control(this.dateHelper.getCurrentTime(), [Validators.required]),
      fin: this.fb.control(this.dateHelper.getOffsetLocal(this.data.periodo.fechaFin), [Validators.required]),
      // eslint-disable-next-line max-len
      horaFin: this.mostrarHoras ? this.fb.control(this.dateHelper.getCurrentTime(1), [Validators.required]): this.fb.control(this.dateHelper.getCurrentTime(1), [Validators.required]) ,
      comentario: ['', Validators.maxLength(255)],
      shared: [false]
    },
    {validators: validarFechaHora});

    this.thirdStep1 = this.fb.group({
      grupo: new UntypedFormControl('', Validators.required)
    });

    this.thirdStep2 = this.fb.group({
      alumnosAsignados: new UntypedFormControl([], Validators.required),
      curso: this.fb.control(this.data.cursos, [Validators.required]),
      nombreGrupo: new UntypedFormControl('')
    });


    this.gestionAlumnoManagementService.getMisClases();
    this.gestionAlumnoManagementService.getMisAlumnos();
    this.gestionAlumnoManagementService.getMisGruposPersonalizados();

    forkJoin(
      this.gestionAlumnoManagementService.misClases$.pipe(take(2)),
      this.gestionAlumnoManagementService.misGruposPersonalizados$.pipe(take(2), takeUntil(this.destroy$))
    ).subscribe(
      ([clases, gruposPersonalizados]) => {
        this.grupos[0] = clases.map(el => ({
          nombre: `${el.curso.ordinal} ${el.curso?.etapa?.acronimo} ${el.grupo.id}`,
          clase: { curso: el.curso, grupo: el.grupo },
          alumnos: el.alumnos,
          tipoAsignacion: 'Clase'
        }));
        this.grupos[1] = gruposPersonalizados.map(el => ({
          id: el.id,
          nombre: el.nombre,
          alumnos: el.alumnos,
          tipoAsignacion: 'GrupoPersonalizado'
        }));
        this.setAlumnosListMulti();
        this.setAsignacionOnInit();
        timer(1000)
          .pipe(take(1), takeUntil(this.destroy$))
          .subscribe(() => {
            this.setAlumnosListMulti();
            this.setAsignacionOnInit();
          });
      }
    );

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onStepperChange(ev) {
    this.currentStep = ev.selectedIndex;

    if (this.currentStep === 1) {
      this.secondStep.markAsPristine();
      this.secondStep.markAsUntouched();
      this.secondStep.reset(this.secondStep.value);
    }

    let info;
    if (this.currentStep === 1) {
      info = {
        event: 'popup_steps',
        popupSteps: {
          action: 'anterior/siguiente',
          popupId: 'dialog-asignar-tarea',
          popupName: 'popup_asignar_tarea',
          popupStep: this.currentStep.toString(),
          shareTeachers: this.secondStep.controls.shared.value.toString()
        }
      };
    } else {
      info = {
        event: 'popup_steps',
        popupSteps: {
          action: 'anterior/siguiente',
          popupId: 'dialog-asignar-tarea',
          popupName: 'popup_asignar_tarea',
          popupStep: this.currentStep.toString()
        }
      };
    }

    this.gtagService.event(info);
  }

  goBack() {
    this.stepperSection.previous();
  }

  goNext() {
    this.stepperSection.next();
  }

  onClose() {
    const result = this.indexTab === 0 ? this.thirdStep1.value : this.thirdStep2.value;
    const _tiposAsignacion = [];

    if (this.seleccionManualAlumnos) {
      _tiposAsignacion.push({
        tipoAsignacion: 'SeleccionAlumnos',
        nombreGrupo: this.thirdStep2.value.nombreGrupo,
        alumnosId: this.alumnosAsignados.map(alumno => alumno.id)
      });
    } else if (this.enableCustomGroup) {
      _tiposAsignacion.push({
        tipoAsignacion: 'GrupoPersonalizado',
        nombreGrupo: this.thirdStep2.value.nombreGrupo,
        alumnosId: this.alumnosAsignados.map(alumno => alumno.id)
      });
    } else {
      this.thirdStep2.value.curso.forEach(seleccionado => {
        _tiposAsignacion.push({
          tipoAsignacion: seleccionado.tipoAsignacion,
          grupoPersonalizadoId: seleccionado.id,
          cursoId: seleccionado.clase?.curso?.id,
          grupoId: seleccionado.clase?.grupo?.id,
          alumnosId: (this.seleccionManualAlumnos) ? this.alumnosAsignados.map(alumno => alumno.id) : null
        });
      });
    }


    this.dialogRef.close({
      ...result,
      ...this.firstStep.value,
      name: this.secondStep.value.name,
      // eslint-disable-next-line max-len
      inicio: this.mostrarHoras ? this.dateHelper.setToUTC(this.dateHelper.mergeDateAndTime(this.secondStep.value.inicio, this.secondStep.value.horaInicio)) : this.dateHelper.setToUTC(this.dateHelper.setFechaInicio(this.secondStep.value.inicio)),
      // eslint-disable-next-line max-len
      fin:this.mostrarHoras ? this.dateHelper.setToUTC(this.dateHelper.mergeDateAndTime(this.secondStep.value.fin, this.secondStep.value.horaFin)) :this.dateHelper.setToUTC(this.dateHelper.setFechaFin(this.secondStep.value.fin)) ,
      comentario: this.secondStep.value.comentario,
      shared: this.secondStep.value.shared,
      tiposAsignacion: _tiposAsignacion,
      step: this.currentStep,
      nombreGrupo: this.thirdStep2.value.nombreGrupo
    });
  }

  checkboxLabel(row?): string {
    return `${this.selection?.isSelected(row.id) ?
      this.translateService.instant('generico.deseleccionar') :
      this.translateService.instant('generico.seleccionar')} ${row.titulo} `;
  }

  isAllSeccionRecursosSelected(seccion) {
    let _return = true;
    if (this.selection?.selected.length === 0 || seccion.recursos.length === 0) {
      return false;
    }
    seccion.recursos.forEach(recurso => {
      if (!this.selection?.selected.includes(recurso.id)) {
        _return = false;
      }
    });
    return _return;
  }

  isSomeSeccionRecursosSelected(seccion) {
    return seccion.recursos.some(recurso => this.selection?.isSelected(recurso.id));

  }

  singleToggle(idModuloSeleccionado): void {
    this.selection?.toggle(idModuloSeleccionado);
    this.firstStep.patchValue({ recursos: this.selection?.selected });
  }

  masterToggle(seccion): void {
    if (this.isAllSeccionRecursosSelected(seccion)) {
      seccion.recursos.forEach(recurso => {
        this.selection?.deselect(recurso.id);
      });
      return;
    }
    seccion.recursos.forEach(recurso => {
      this.selection?.select(recurso.id);
    });

    this.firstStep.patchValue({ recursos: this.selection?.selected });

  }

  tabChange(event: MatTabChangeEvent) {
    this.indexTab = event.index;
    if (this.indexTab < 1) {
      this.thirdStep2.reset(this.thirdStep1.value);
    } else {
      this.thirdStep1.reset(this.thirdStep2.value);
    }
  }

  setAlumnosListMulti() {

    this.thirdStep2.get('curso');
    // eslint-disable-next-line prefer-const
    let alumnos = [];

    this.alumnos = [];
    this.alumnosTemp = [];
    this.alumnosAsignados = [];
    this.habilitarSeleccionManualAlumnos = true;

    this.thirdStep2.get('curso').value.forEach(grupo => {
      this.setAlumnosList(grupo, alumnos);
    });

    this.alumnos = this.alumnos.concat(this.alumnosTemp.filter(alumno => !this.alumnosAsignados.some(aluAsi => aluAsi.id === alumno.id)));

    if (!this.seleccionManualAlumnos) {
      this.asignarTodos();
      this.alumnosAsignados = this.alumnosAsignados.concat(alumnos);
    } else {
      this.desasignarAlumnos();
      this.alumnosAsignados = [];
    }

    const alumnosDuplicados = this.alumnosAsignados.filter((obj, index, arr) => arr.findIndex(t => t.id === obj.id) !== index);

    if (alumnosDuplicados?.length > 0) {

      this.scrollService.bloqueateScroll();
      const dialogRef = this.dialog.open(GenericComponent, {
        data: {
          titulo: this.translateService.instant('generico.atencion'),
          icon: 'info',
          contenido: this.translateService.instant('dialogs.asignar-tarea.step3.aumnios-repetidos', {
            alumnos: alumnosDuplicados.map(a => `${a.nombre} ${a.apellidoUno} ${a.apellidoDos}`).join(', \n')
          }),
          color: 'standard',
          close: true,
          actions: [
            {
              key: 'generico.cerrar',
              type: 'secondary',
              onClose: false,
            }
          ],
          event: event,
          action: 'eliminar'
        },
        restoreFocus: true,
        disableClose: true
      });

      dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$),).subscribe(() => {
        this.scrollService.desbloqueateScroll();
      });
    }
  }

  setAlumnosList(grupo, alumnos) {
    let _alumnos = (alumnos) ? alumnos : [];
    // si no hay clase es que es un grupo personalizado y trae el array de alumnos
    if (!grupo.clase) {
      _alumnos = grupo.alumnos;
      this.habilitarSeleccionManualAlumnos = false;
      this.seleccionManualAlumnos = false;
    } else {
      _alumnos = this.gestionAlumnoManagementService.getListaAlumnosEnUnaClase(grupo.clase, this.gestionAlumnoManagementService.misAlumnos);
    }
    // this.alumnosTemp = (alumnos && alumnos.length > 0) ? alumnos : [];


    if (_alumnos && _alumnos.length > 0) {
      this.alumnosTemp = this.alumnosTemp.concat(_alumnos);
    }

  }

  asignarAlumnos() {
    this.alumnosAsignados = this.alumnosAsignados.concat(this.selectionDisponibles.selected);

    this.thirdStep2.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.alumnos = this.alumnos.filter(alumno => !this.selectionDisponibles.selected.includes(alumno));
    this.selectionDisponibles.clear();
  }

  asignarTodos() {
    this.alumnos.forEach((row) => this.selectionDisponibles.select(row));
    this.asignarAlumnos();
  }

  desasignarAlumnos() {
    this.alumnos = this.alumnos.concat(this.alumnosTemp.filter(alumno => this.selectionAsignados.selected.includes(alumno)));

    this.alumnosAsignados = this.alumnosAsignados.filter(alumno => !this.selectionAsignados.selected.includes(alumno));
    this.thirdStep2.controls.alumnosAsignados.setValue(this.alumnosAsignados);
    this.selectionAsignados.clear();
  }

  desasignarTodos() {
    this.alumnosAsignados.forEach((row) => this.selectionAsignados.select(row));
    this.desasignarAlumnos();
  }

  setAsignacionOnInit() {
    const cursosSeleccionados = this.grupos
      .map(grupo => grupo.filter(g => this.data.cursos.some(curso =>
        (curso.grupoId === g?.clase?.grupo?.id &&
          curso.cursoId === g?.clase?.curso?.id) || curso.grupoPersonalizadoId === g.id
      )))
      .reduce((acc, val) => acc.concat(val), []);
    this.thirdStep2.get('curso').setValue(cursosSeleccionados);
  }

  onToggleChange(event: any): void {
    this.mostrarHoras = event.checked;
    if (this.mostrarHoras) {
      this.secondStep.patchValue({
        fin: this.dateHelper.getOffsetLocal(this.secondStep.value.inicio),
        horaFin: this.dateHelper.getCurrentTime(1)
      });
    } else {
        
      this.secondStep.patchValue({
        fin: this.dateHelper.getOffsetLocal(this.data.periodo.fechaFin),
        horaFin: this.dateHelper.getCurrentTime()
      });
    }}

    isLTI() {
      return this.ltiManagementService.moodle;
    }

}
