
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ActividadAsignacionEstado } from '../../features/main/tareas/actividad/actividad-plataforma/actividad-plataforma.component';

@Injectable({
  providedIn: 'root'
})
export class DateHelper {

  browserLanguage = navigator.language;

  getOffsetLocal(date) {
    if (date) {

      return moment.utc(date).local().format();
    }
    return '';
  }

  setToUTC(date) {
    if (date) {

      return moment.utc(date).format();
    }
    return '';
  }

  setFechaInicio(date) {
    if (date) {
      return moment(date).startOf('day').local().format();
    }
    return '';
  }

  setFechaFin(date) {
    if (date) {
      return moment(date).endOf('day').local().format();
    }
    return '';
  }

  disableWeekendsFilter(d: Date) {
    return (d.getDay() !== 0 && d.getDay() !== 6);
  }


  mergeDateAndTime(date: string | Date, time: string): string {
    if (date && time) {
      const [hours, minutes] = time.split(':').map(Number);
      const combinedDate = moment(date).set({ hour: hours, minute: minutes, second: 0 });

      return combinedDate.format();

    }
    return '';
  }


  getCurrentTime(hoursToAdd: number = 0): string {
    const now = moment();
    if (hoursToAdd) {
      now.add(hoursToAdd, 'hours');
    }

    return now.format('HH:mm');
  }


  setFechaFinUTC(date) {
    if (date) {
      return moment(date).endOf('day'); // Devuelve la fecha UTC sin alteraciones
    }
    return '';
  }


  // checkEntrega(fechaFin) {

   
  //   const finDelDiaUTC = this.setFechaFinUTC(fechaFin);
  //   const finDelDiaUTCFormatted = moment.isMoment(finDelDiaUTC) ? finDelDiaUTC.format() : finDelDiaUTC;
  //   const ahora = moment().utc();


  //     const puedeEntregarHoy = ahora.isSameOrBefore(finDelDiaUTCFormatted);

  //     return puedeEntregarHoy;

   
  //   }


 checkEntrega(fechaFin, estado) {

    const fechaFinVigencia = moment.utc(fechaFin);

    const finDelDiaUTC = this.setFechaFinUTC(fechaFin);
    const finDelDiaUTCFormatted = moment.isMoment(finDelDiaUTC) ? finDelDiaUTC.format() : finDelDiaUTC;
    const ahora = moment().utc();


    if (estado === ActividadAsignacionEstado.Iniciada) {

      const puedeEntregarHoy = ahora.isSameOrBefore(finDelDiaUTCFormatted);

      return puedeEntregarHoy;

    } else if (estado === ActividadAsignacionEstado.Realizada || estado === ActividadAsignacionEstado.PendienteRevisar) {

      return true;

    } else {

      const puedeEntregarHoy = ahora.isSameOrBefore(moment(fechaFinVigencia).utc());

      return puedeEntregarHoy;
    }




  }


  }
 





