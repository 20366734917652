/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GtagService } from '../../../../../../services/gtag/gtag.service';
import { AddModulesBaseComponent } from '../../add-modules-base.component';
import { EditionModeService } from '../../../../../../services/edition-mode.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'aula-planeta-add-module-seccion',
  templateUrl: './seccion.component.html',
  styleUrls: ['./seccion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeccionComponent extends AddModulesBaseComponent {

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    protected fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SeccionComponent>,
    public editionModeService: EditionModeService,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    super(fb, dialogRef, data);
  }

  ngOnInit() {


    this.editionModeService.enable$
    .pipe(takeUntil(this.destroy$))
    .subscribe(enable => {
      
      if (!enable) {
        this.dialogRef.close();
        return;
      }

      this.formModule = this.fb.group({
        titulo_html: [this.data && this.data.propiedades.titulo_plano ?
          this.data.propiedades.titulo_plano : '', Validators.required],
        descripcion_html: [this.data && this.data.propiedades.descripcion_html ?
          this.data.propiedades.descripcion_html : ''],
        visible: [this.data && this.data.propiedades.visible === 'Profesores' ?
          true : false, Validators.required],
      });

      const info = {
        event: 'popup',
        popup: {
          action: 'attach_module_section',
          popupId: 'dialog-add-module-seccion',
          popupName: 'popup_añadir_modulo_seccion'
        }
      };
      this.gtagService.event(info);
    });
  }
}
