/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, OnDestroy, NgZone } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ScormDataService } from './scorm-data-service.service';
import { TareaManagementService } from '../tarea/tarea-management.service';
import { TemasManagementService } from '../temas/temas-management.service';
import { AuthManagementService } from '../../../core/auth/auth-management.service';
import { ProgressSpinnerService } from '../../../core/progress-spinner/progress-spinner.service';
import { GenericComponent } from '../../../shared/components/dialogs/generic/generic.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { RetryService } from '../../retry.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LtiManagementService } from '../lti/lti-management.service';
import { ScrollService } from '../../../core/scroll/scroll.service';


@Injectable({
  providedIn: 'root'
})
export class ScormManagementService implements OnDestroy {


  scorm$: Observable<string>;
  scormData$: Observable<any>;

  private _scorm: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _scormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  private destroy$: Subject<void> = new Subject<void>();

  noResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  static scormDataService: any;

  constructor(
    private scormDataService: ScormDataService,
    private tareaManagementService: TareaManagementService,
    private temasManagementService: TemasManagementService,
    private authManagementService: AuthManagementService,
    private ltiManagementService: LtiManagementService,
    private translateService: TranslateService,
    private progressSpinnerService: ProgressSpinnerService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private retryService: RetryService,
    private scrollService: ScrollService
  ) {
    this.scorm$ = this._scorm.asObservable();
    this.scormData$ = this._scormData.asObservable();
    ScormManagementService.scormDataService = this.scormDataService;

    this.scormData$
      .pipe(
        takeUntil(this.destroy$))
      .subscribe(data => {
        if (data) {
          console.log('ScormManagementService:', data);
          if (data.idActividad) {
            if (this.authManagementService.currentUser.usuarioRoles.includes('Alumno')) {
              // if ((data.typeScorm === 'mauthor' && data.status === 'completed') || data.typeScorm !== 'mauthor') {

              if (data.caducada) {
                this.tareaManagementService.openCaducada = true;
                const currentUrl = this.router.url;
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                  this.router.navigate([currentUrl]);
                });
              } else {

                setTimeout(() => {
                  this.zone.run(() => {
                    this.progressSpinnerService.spin$.next(true);
                  });
                }, 1);

                this.actualizarScorm(data.idActividad, data.datosScorm, data.autonoma, data.diferenciaMinutosUTC).pipe(
                  take(1),
                  takeUntil(this.destroy$))
                  .subscribe(() => {
                    setTimeout(() => {
                      this.zone.run(() => {
                        this.progressSpinnerService.spin$.next(false);
                        //     this.progressSpinnerService.stopSpinner();
                      });
                    }, 1);
                    this.tareaManagementService.getTarea(data.autonoma, data.idTarea, false);

                    if (data.status === 'unknown' && data.tipoEvaluable === 'abierta') {
                      const message = this.translateService.instant('generico.actividad.guardada');
                      this.snackBar.open(message, 'X', {
                        duration: 4000,
                        panelClass: 'success-notification-overlay-new'
                      });
                    }
                    if (data.status === 'incomplete' && data.typeScorm === 'articulate' && data.estado === 3) {
                      const message = this.translateService.instant('generico.actividad.guardada');
                      this.snackBar.open(message, 'X', {
                        duration: 4000,
                        panelClass: 'success-notification-overlay-new'
                      });
                    }
                    if (data.status === 'completed' && data.tipoEvaluable === 'abierta') {
                      const message = this.translateService.instant('generico.actividad.enviada');
                      this.snackBar.open(message, 'X', {
                        duration: 4000,
                        panelClass: 'success-notification-overlay-new'
                      });
                    }
                    if (data.status === 'completed' && data.tipoEvaluable === 'autocorregible') {
                      const message = this.translateService.instant('generico.actividad.enviada.evaluada');
                      this.snackBar.open(message, 'X', {
                        duration: 4000,
                        panelClass: 'success-notification-overlay-new'
                      });
                    }
                  },
                    _error => {
                      setTimeout(() => {
                        this.zone.run(() => {
                          this.progressSpinnerService.spin$.next(false);
                          this.scrollService.bloqueateScroll();
                          const dialogRef = this.dialog.open(GenericComponent, {
                            data: {
                              titulo: this.translateService.instant('generico.atencion'),
                              icon: 'info',
                              contenido: this.translateService.instant('seguimiento-alumno.actividad.error-repetir'),
                              color: 'standard',
                              close: true,
                              actions: [
                                {
                                  key: 'generico.cerrar',
                                  type: 'secondary',
                                  onClose: false,
                                }
                              ],
                              event: event,
                              action: 'eliminar'
                            },
                            restoreFocus: true,
                            disableClose: true
                          });

                          dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
                            this.scrollService.desbloqueateScroll();
                            const currentUrl = this.router.url;
                            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                              this.router.navigate([currentUrl]);
                            });
                          });
                        });
                      }, 1);
                    });
              }
              // }
            } else {
              // if (data.typeScorm !== 'articulate') {
              //   this.tareaManagementService.getTarea(data.autonoma, data.idTarea, false);
              // }
            }
            // } else if (!data.idActividad && data.status === 'completed') {
          } else if (!data.idActividad) {

            console.log('data realizarScorm: ', data);
            let payload = {
              materiaId: data.idMateria,
              temaId: data.idTema,
              moduloId: data.idRecurso,

              scorm: {
                datosScorm: data.datosScorm
              }
            } as any;

            if (this.authManagementService.userCanPermission(['AccesoLtiTareas'])) {

              const moodleTeacherId = this.ltiManagementService.moodleTeacherId ?
                this.ltiManagementService.moodleTeacherId : localStorage.getItem('moodleTeacherId') as any;


              payload = { moodleTeacherId, ...payload };
            }

            if (this.authManagementService.currentUser.usuarioRoles.includes('Alumno') && !data.inline) {
              setTimeout(() => {
                this.zone.run(() => {
                  this.progressSpinnerService.spin$.next(true);
                  // console.log('arranca2');

                });
              }, 1);
              this.realizarScorm(payload, this.authManagementService.userCanPermission(['AccesoLtiTareas'])).pipe(
                take(1),
                takeUntil(this.destroy$))
                .subscribe(() => {
                  setTimeout(() => {
                    this.zone.run(() => {
                      this.progressSpinnerService.spin$.next(false);
                      // this.progressSpinnerService.stopSpinner();
                    });
                  }, 1);
                  // this.temasManagementService.getRecurso(data.idTema, data.idRecurso);
                },
                  error => {
                    console.log(error);
                    setTimeout(() => {
                      this.zone.run(() => {
                        this.progressSpinnerService.spin$.next(false);
                        // this.progressSpinnerService.stopSpinner();
                      });
                    }, 1);
                  });
            } else {
              // this.temasManagementService.getRecurso(data.idTema, data.idRecurso);
            }
          }
        }

      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get scorm() {
    return this._scorm.getValue();
  }

  set scorm(scorm: string) {
    this._scorm.next(scorm);
  }

  get scormData() {
    return this._scormData.getValue();
  }

  set scormData(scormData: any) {
    this._scormData.next(scormData);
  }

  actualizarScorm(idActividad, datosScorm, autonoma, diferenciaMinutosUTC?): Observable<any> {
    // eslint-disable-next-line max-len
    return this.retryService.retryWithBackoff(this.scormDataService.actualizarScorm(idActividad, datosScorm, autonoma, diferenciaMinutosUTC), 1000);
  }
  

  iniciarScorm(idActividad) {
    this.scormDataService.iniciarScorm(idActividad).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe();
  }

  realizarScorm(data, isMoodle): Observable<any> {
    return this.scormDataService.realizarScorm(data, isMoodle);
  }

}
