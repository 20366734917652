import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BuscadorDataService } from './buscador-data.service';
import { RecursoBuscador, SeccionBuscador, TemaBuscador, RecursoBancoBuscador } from '../../../shared/models/buscador-resultados.model';

@Injectable({
  providedIn: 'root'
})
export class BuscadorManagementService implements OnDestroy {


  isLoading = false;

  bancoContenidosActivo = false;

  recursos$: Observable<RecursoBuscador[]>;
  private _recursos: BehaviorSubject<RecursoBuscador[]> = new BehaviorSubject<RecursoBuscador[]>([]);

  secciones$: Observable<SeccionBuscador[]>;
  private _secciones: BehaviorSubject<SeccionBuscador[]> = new BehaviorSubject<SeccionBuscador[]>([]);

  temas$: Observable<TemaBuscador[]>;
  private _temas: BehaviorSubject<TemaBuscador[]> = new BehaviorSubject<TemaBuscador[]>([]);

  recursosBanco$: Observable<RecursoBancoBuscador[]>;
  private _recursosBanco: BehaviorSubject<RecursoBancoBuscador[]> = new BehaviorSubject<RecursoBancoBuscador[]>([]);

  private destroy$: Subject<void> = new Subject<void>();

  noResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  accionesdidacticas$: Observable<any>;
  private _accionesdidacticas: BehaviorSubject<any> = new BehaviorSubject<any>([])

  bloquescontenidos$: Observable<any>;
  private _bloquescontenidos: BehaviorSubject<any> = new BehaviorSubject<any>([])

  competenciasclaves$: Observable<any>;
  private _competenciasclaves: BehaviorSubject<any> = new BehaviorSubject<any>([])

  criteriosevaluaciones$: Observable<any>;
  private _criteriosevaluaciones: BehaviorSubject<any> = new BehaviorSubject<any>([])

  cursos$: Observable<any>;
  private _cursos: BehaviorSubject<any> = new BehaviorSubject<any>([])

  estandaresaprendizajes$: Observable<any>;
  private _estandaresaprendizajes: BehaviorSubject<any> = new BehaviorSubject<any>([])

  etapas$: Observable<any>;
  private _etapas: BehaviorSubject<any> = new BehaviorSubject<any>([])

  idiomas$: Observable<any>;
  private _idiomas: BehaviorSubject<any> = new BehaviorSubject<any>([])

  materias$: Observable<any>;
  private _materias: BehaviorSubject<any> = new BehaviorSubject<any>([])

  nivelesdificultades$: Observable<any>;
  private _nivelesdificultades: BehaviorSubject<any> = new BehaviorSubject<any>([])

  taxonomiasblooms$: Observable<any>;
  private _taxonomiasblooms: BehaviorSubject<any> = new BehaviorSubject<any>([])

  tiposcontenidos$: Observable<any>;
  private _tiposcontenidos: BehaviorSubject<any> = new BehaviorSubject<any>([])

  tiposmedia$: Observable<any>;
  private _tiposmedia: BehaviorSubject<any> = new BehaviorSubject<any>([])

  tiposmediarecursos$: Observable<any>;
  private _tiposmediarecursos: BehaviorSubject<any> = new BehaviorSubject<any>([])

  tematica$: Observable<any>;
  private _tematica: BehaviorSubject<any> = new BehaviorSubject<any>([])

  totalRecursos: number;

  // altoVS$: Observable<number>;
  // private _altoVS: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private buscadorDataService: BuscadorDataService
  ) {
    this.recursos$ = this._recursos.asObservable();
    this.secciones$ = this._secciones.asObservable();
    this.temas$ = this._temas.asObservable();
    this.recursosBanco$ = this._recursosBanco.asObservable();

    this.accionesdidacticas$ = this._accionesdidacticas.asObservable();
    this.bloquescontenidos$ = this._bloquescontenidos.asObservable();
    this.competenciasclaves$ = this._competenciasclaves.asObservable();
    this.criteriosevaluaciones$ = this._criteriosevaluaciones.asObservable();
    this.cursos$ = this._cursos.asObservable();
    this.estandaresaprendizajes$ = this._estandaresaprendizajes.asObservable();
    this.etapas$ = this._etapas.asObservable();
    this.idiomas$ = this._idiomas.asObservable();
    this.materias$ = this._materias.asObservable();
    this.nivelesdificultades$ = this._nivelesdificultades.asObservable();
    this.taxonomiasblooms$ = this._taxonomiasblooms.asObservable();
    this.tiposcontenidos$ = this._tiposcontenidos.asObservable();
    this.tiposmediarecursos$ = this._tiposmediarecursos.asObservable();
    this.tiposmedia$ = this._tiposmedia.asObservable();
    this.tematica$ = this._tematica.asObservable();

    // this.altoVS$ = this._altoVS.asObservable();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get recursos() {
    return this._recursos.getValue();
  }

  get secciones() {
    return this._secciones.getValue();
  }

  get temas() {
    return this._temas.getValue();
  }

  get recursosBanco() {
    return this._recursosBanco.getValue();
  }

  set recursos(recursos: RecursoBuscador[]) {
    this._recursos.next(recursos);
  }

  set secciones(secciones: SeccionBuscador[]) {
    this._secciones.next(secciones);
  }

  set temas(temas: TemaBuscador[]) {
    this._temas.next(temas);
  }

  set recursosBanco(recursosBanco: RecursoBancoBuscador[]) {
    this._recursosBanco.next(recursosBanco);
  }

  set accionesdidacticas(accionesdidacticas: any) {
    this._accionesdidacticas.next(accionesdidacticas);
  }

  set bloquescontenidos(bloquescontenidos: any) {
    this._bloquescontenidos.next(bloquescontenidos);
  }

  set competenciasclaves(competenciasclaves: any) {
    this._competenciasclaves.next(competenciasclaves);
  }

  set criteriosevaluaciones(criteriosevaluaciones: any) {
    this._criteriosevaluaciones.next(criteriosevaluaciones);
  }

  set cursos(cursos: any) {
    this._cursos.next(cursos);
  }

  set estandaresaprendizajes(estandaresaprendizajes: any) {
    this._estandaresaprendizajes.next(estandaresaprendizajes);
  }

  set etapas(etapas: any) {
    this._etapas.next(etapas);
  }

  set idiomas(idiomas: any) {
    this._idiomas.next(idiomas);
  }

  set materias(materias: any) {
    this._materias.next(materias);
  }

  set nivelesdificultades(nivelesdificultades: any) {
    this._nivelesdificultades.next(nivelesdificultades);
  }

  set taxonomiasblooms(taxonomiasblooms: any) {
    this._taxonomiasblooms.next(taxonomiasblooms);
  }

  set tiposcontenidos(tiposcontenidos: any) {
    this._tiposcontenidos.next(tiposcontenidos);
  }

  set tiposmediarecursos(tiposmediarecursos: any) {
    this._tiposmediarecursos.next(tiposmediarecursos);
  }

  set tiposmedia(tiposmedia: any) {
    this._tiposmedia.next(tiposmedia);
  }

  set tematica(tematica: any) {
    this._tematica.next(tematica);
  }

  // set altoVS(altoVs: number) {
  //   this._altoVS.next(altoVs);
  // }

  get accionesdidacticas() {
    return this._accionesdidacticas.getValue();
  }

  get bloquescontenidos() {
    return this._bloquescontenidos.getValue();
  }

  get competenciasclaves() {
    return this._competenciasclaves.getValue();
  }

  get criteriosevaluaciones() {
    return this._criteriosevaluaciones.getValue();
  }

  get cursos() {
    return this._cursos.getValue();
  }

  get estandaresaprendizajes() {
    return this._estandaresaprendizajes.getValue();
  }

  get etapas() {
    return this._etapas.getValue();
  }

  get idiomas() {
    return this._idiomas.getValue();
  }

  get materias() {
    return this._materias.getValue();
  }

  get nivelesdificultades() {
    return this._nivelesdificultades.getValue();
  }

  get taxonomiasblooms() {
    return this._taxonomiasblooms.getValue();
  }

  get tiposcontenidos() {
    return this._tiposcontenidos.getValue();
  }

  get tiposmediarecursos() {
    return this._tiposmediarecursos.getValue();
  }

  get tiposmedia() {
    return this._tiposmedia.getValue();
  }

  get tematica() {
    return this._tematica.getValue();
  }

  // get altoVS() {
  //   return this._altoVS.getValue();
  // }

  getFiltros() {

    this.getAccionesdidacticas();
    this.getCompetenciasclaves();
    this.getCursos();
    this.getIdiomas();
    this.getMaterias();
    this.getNivelesdificultades();
    this.getTaxonomiasblooms();
    this.getTiposcontenidos();
    this.getTiposmedia();

  }


  getAccionesdidacticas() {
    this.buscadorDataService.getAccionesdidacticas().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.accionesdidacticas = res;
        }
      );
  }


  getCompetenciasclaves() {
    this.buscadorDataService.getCompetenciasclaves().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.competenciasclaves = res;
        }
      );

  }

  getCursos() {
    this.buscadorDataService.getCursos().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.cursos = res;
        }
      );
  }


  getIdiomas() {
    this.buscadorDataService.getIdiomas().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.idiomas = res;
        }
      );
  }

  getMaterias() {
    this.buscadorDataService.getMaterias().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.materias = res;
        }
      );
  }

  getNivelesdificultades() {
    this.buscadorDataService.getNivelesdificultades().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.nivelesdificultades = res;
        }
      );
  }

  getTaxonomiasblooms() {
    this.buscadorDataService.getTaxonomiasblooms().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.taxonomiasblooms = res;
        }
      );
  }

  getTiposcontenidos() {
    this.buscadorDataService.getTiposcontenidos().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.tiposcontenidos = res;
        }
      );
  }

  getTiposmedia() {
    this.buscadorDataService.getTiposmedia().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.tiposmediarecursos = res;
        }
      );
  }



  getBuscador(value: any, isBancoActive = false) {
    if (value === '') {
      this.recursos = [];
      this.secciones = [];
      this.temas = [];
      this.recursosBanco = [];
      return;
    }

    // const enumo = {
    //   1: 'Novel',
    //   2: 'Aprendizaje',
    //   3: 'Avanzado',
    //   4: 'Experto'
    // };

    // value.nivelDificultad = enumo[value.nivelDificultad];

    const removeProp = [
      'accionDidactica',
      'competenciasClave',
      'idioma',
      'nivelDificultad',
      'taxonomiaBloom',
      'tipoContenido',
      'tipoMediaRecurso'
    ];

    this.buscadorDataService.getRecursos(value).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(recursos => {
        if (recursos.totalRecursos >= 0) {
          this.recursos = recursos.recursos;
          this.totalRecursos = recursos.totalRecursos;
        } else {
          this.recursos = recursos;
          this.totalRecursos = recursos.length;
        }

      });

    const valuelight = { ...value };
    removeProp.forEach(prop => {
      delete valuelight[prop];
    });

    this.buscadorDataService.getTemas(valuelight).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(temas => {
        this.temas = temas;
      });

    this.buscadorDataService.getSecciones(valuelight).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(secciones => {
        this.secciones = secciones;
      });


    if (isBancoActive) {
      this.buscadorDataService.getRecursosBanco(valuelight.texto).pipe(
        take(1),
        takeUntil(this.destroy$))
        .subscribe(recursosBanco => {
          this.recursosBanco = recursosBanco;
        });

    }
  }


  getRecursosLtiAndalucia(filtro: any) {

    this.buscadorDataService.getRecursosLtiAndalucia(filtro).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(recursos => {
        if (recursos.totalRecursos >= 0) {
          this.recursos = recursos.recursos;
          this.totalRecursos = recursos.totalRecursos;
        } else {
          this.recursos = recursos;
          this.totalRecursos = recursos.length;
        }
      });

  }


  getRecursosLtiMoodleEdixgal(filtro: any) {
    if (filtro.texto.lenght === 0) {
      this.recursos = [];
      // this.recursosBanco = [];
      return;
    }
    this.buscadorDataService.getRecursosLtiMoodleEdixgal(filtro).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(recursos => {
        if (recursos.totalRecursos >= 0) {
          this.recursos = recursos.recursos;
          this.totalRecursos = recursos.totalRecursos;
        } else {
          this.recursos = recursos;
          this.totalRecursos = recursos.length;
        }
      });

  }

  getRecursosLtiMoodleGenerico(filtro: any) {
    this.isLoading = true;
    // if (filtro.texto.lenght === 0) {
    // this.recursos = [];
    // this.recursosBanco = [];
    // return;
    // }
    this.buscadorDataService.getRecursosLtiMoodleGenerico(filtro).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(recursos => {
        if (recursos.totalRecursos >= 0) {
          this.isLoading = false;
          this.recursos = recursos.recursos;
          this.totalRecursos = recursos.totalRecursos;
        } else {
          this.isLoading = false;
          this.recursos = recursos;
          this.totalRecursos = recursos.length;
        }
      });

  }

  getBancoLtiAndalucia(filtro: any) {
    this.isLoading = true;
    if (!filtro.texto || filtro.texto?.lenght === 0) {
      // this.recursos = [];
      this.recursosBanco = [];
      this.isLoading = false;
      return;
    }

    this.buscadorDataService.getRecursosBancoLtiAndalucia(filtro).pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe(recursosBanco => {
        this.isLoading = false;
        this.recursosBanco = recursosBanco;
      });

  }

  getRecursosAnadir(filtro: any) {
    if (filtro) {
      this.buscadorDataService.getRecursosAnadir(filtro).pipe(
        take(1),
        takeUntil(this.destroy$))
        .subscribe(recursos => {
          if (recursos.totalRecursos >= 0) {
            this.recursos = recursos.recursos;
            this.totalRecursos = recursos.totalRecursos;
          } else {
            this.recursos = recursos;
            this.totalRecursos = recursos.length;
          }

        });
    }
  }


}
