import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
  transform(value, strToReplace, replacementStr) {
    if (!value || !strToReplace || !replacementStr) {
      return value;
    }

    if(!isNaN(value)) {
      value = String(value);
    }

    return value.replace(strToReplace, replacementStr);
  }
}