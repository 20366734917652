/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faCaretUp,
  faCaretDown,
  faExclamationTriangle,
  faFilter,
  faTasks,
  faCheck,
  faSquare,
  faLanguage,
  faPaintBrush,
  faLightbulb,
  faWindowMaximize,
  faStream,
  faBook

} from '@fortawesome/free-solid-svg-icons';
import { faMediumM, faGithub } from '@fortawesome/free-brands-svg-icons';

import { BigInputComponent } from './big-input/big-input/big-input.component';
import { BigInputActionComponent } from './big-input/big-input-action/big-input-action.component';
import { RtlSupportDirective } from './rtl-support/rtl-support.directive';
import { MaterialModule } from '../material/material.module';
import { CrearTemaNuevoComponent } from './components/dialogs/crear-tema-nuevo/crear-tema-nuevo.component';
import { ProgramacionAulaComponent } from './components/dialogs/programacion-aula/programacion-aula.component';
import { MensajeNotificacionComponent } from './components/dialogs/mensaje-notificacion/mensaje-notificacion.component';
import { DesasignarAlumnadoComponent } from './components/dialogs/desasignar-alumnado/desasignar-alumnado.component';
import { OcultarComponent } from './components/dialogs/ocultar/ocultar.component';
import { DuplicarComponent } from './components/dialogs/duplicar/duplicar.component';
import { DescargarPdfComponent } from './components/dialogs/descargar-pdf/descargar-pdf.component';
import { DescargarCsvComponent } from './components/dialogs/descargar-csv/descargar-csv.component';
import { EliminarComponent } from './components/dialogs/eliminar/eliminar.component';
import { DirectivesModule } from '../core/directives/directives.module';
import { SafeModule } from '../core/safe/safe.module';

import { MiClaseComponent } from './components/dialogs/mi-clase/mi-clase.component';
import { DescargarOfflineComponent } from './components/dialogs/descargar-offline/descargar-offline.component';
import { StartCompartirComponent } from './components/dialogs/start-compartir/start-compartir.component';
import { CopiarEnlaceComponent } from './components/dialogs/copiar-enlace/copiar-enlace.component';
import { MoverCopiarElementoComponent } from './components/dialogs/mover-copiar-elemento/mover-copiar-elemento.component';
import { EnviarComentarioComponent } from './components/dialogs/enviar-comentario/enviar-comentario.component';
import { CopiarComponent } from './components/dialogs/copiar/copiar.component';
import { MoverComponent } from './components/dialogs/mover/mover.component';
import { ImagenDestacadaComponent } from './components/dialogs/imagen-destacada/imagen-destacada.component';
import { EditarMetadatosComponent } from './components/dialogs/editar-metadatos/editar-metadatos.component';
import { TareaComponent } from './components/dialogs/tarea/tarea.component';
import { ModificarTareaComponent } from './components/dialogs/modificar-tarea/modificar-tarea.component';
import { DuplicarTareaComponent } from './components/dialogs/duplicar-tarea/duplicar-tarea.component';
import { AdjuntarFicheroComponent } from './components/dialogs/adjuntar-fichero/adjuntar-fichero.component';
import { GuiaDidacticaComponent } from './components/dialogs/guia-didactica/guia-didactica.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RepetirTareaComponent } from './components/dialogs/repetir-tarea/repetir-tarea.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddSeccionComponent } from './components/dialogs/add-seccion/add-seccion.component';
import { AddSubSeccionComponent } from './components/dialogs/add-subseccion/add-subseccion.component';
import { GenericComponent } from './components/dialogs/generic/generic.component';
import { ConfigurarAulaplanetaComponent } from './components/dialogs/configurar-aulaplaneta/configurar-aulaplaneta.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { NgxPrintModule } from 'ngx-print';
import { AsignarTareaComponent } from './components/dialogs/asignar-tarea/asignar-tarea.component';
import { CrearEnlaceComponent } from './components/dialogs/crear-enlace/crear-enlace.component';
import { SubirImagenComponent } from './components/dialogs/subir-imagen/subir-imagen.component';
import { EditarRecursoComponent } from './components/dialogs/editar-recurso/editar-recurso.component';
import { ReasignarTareaComponent } from './components/dialogs/reasignar-tarea/reasignar-tarea.component';
import { NuevoGrupoAlumnosComponent } from './components/dialogs/nuevo-grupo-alumnos/nuevo-grupo-alumnos.component';
import { FiltrarResultadosComponent } from './components/dialogs/filtrar-resultados/filtrar-resultados.component';
import { ModificarTemaComponent } from './components/dialogs/modificar-tema/modificar-tema.component';
import { AsignarUnidadComponent } from './components/dialogs/asignar-unidad/asignar-unidad.component';
import { CambiarVistaComponent } from './components/dialogs/cambiar-vista/cambiar-vista.component';
import { CambiarContrasenaComponent } from './components/dialogs/cambiar-contrasena/cambiar-contrasena.component';
import { ConfiguracionCookiesComponent } from './components/dialogs/configuracion-cookies/configuracion-cookies.component';
import { EditarRecursoConfirmComponent } from './components/dialogs/editar-recurso-confirm/editar-recurso-confirm.component';
import { AsignarTareaRapidaComponent } from './components/dialogs/asignar-tarea-rapida/asignar-tarea-rapida.component';
import { ConfigurarOtrasMateriasComponent } from './components/dialogs/configurar-otras-materias/configurar-otras-materias.component';
import { CrearMateriaComponent } from './components/dialogs/configurar-otras-materias/components/crear-materia/crear-materia.component';
import { AddRecursoComponent } from './components/dialogs/add-recurso/add-recurso.component';
import { RepetirActividadComponent } from './components/dialogs/repetir-actividad/repetir-actividad.component';
import { EditionHeaderComponent } from './components/mode-edition/edition-header/edition-header.component';
import { EditionBarComponent } from './components/mode-edition/edition-bar/edition-bar.component';
import { EditionAddComponent } from './components/mode-edition/edition-add/edition-add.component';
import { SeccionComponent } from './components/dialogs/add-modules-base/modulos/seccion/seccion.component';
import { AudioComponent } from './components/dialogs/add-modules-base/modulos/audio/audio.component';
import { EnlaceComponent } from './components/dialogs/add-modules-base/modulos/enlace/enlace.component';
import { DestacadoComponent } from './components/dialogs/add-modules-base/modulos/destacado/destacado.component';
import { DocumentoComponent } from './components/dialogs/add-modules-base/modulos/documento/documento.component';
import { ImagenComponent } from './components/dialogs/add-modules-base/modulos/imagen/imagen.component';
import { TablaComponent } from './components/dialogs/add-modules-base/modulos/tabla/tabla.component';
import { VideoComponent } from './components/dialogs/add-modules-base/modulos/video/video.component';
import { TextoComponent } from './components/dialogs/add-modules-base/modulos/texto/texto.component';
import { JumboTemaComponent } from './components/dialogs/add-modules-base/modulos/jumbo-tema/jumbo-tema.component';
import { AnnotationsComponent } from './components/annotations/annotations.component';
import { AddAnnotationComponent } from './components/dialogs/add-annotation/add-annotation.component';
import { EditRecursoPlantillaComponent } from './components/dialogs/edit-recurso-plantilla/edit-recurso-plantilla.component';
import { ExpositorDialogComponent } from './components/dialogs/expositor/expositor-dialog.component';
import { DrawerDialogComponent } from './components/dialogs/drawer/drawer-dialog.component';
import { PlayerComponent } from './player/aula-planeta-player.component';
import { SecsToMinsPipe } from '../services/pipes/secs-to-mins.pipe';
import { MisTemasPipe } from '../services/pipes/mis-temas.pipe';
import { AsignarTareaClassroomComponent } from './components/dialogs/asignar-tarea-classroom/asignar-tarea-classroom.component';
import { AsignarTareaTeamsComponent } from './components/dialogs/asignar-tarea-teams/asignar-tarea-teams.component';
import { CrearMateriaPersonalizadaComponent } from './components/dialogs/crear-materia-personalizada/crear-materia-personalizada.component';
import { DuplicarMateriaPersonalizadaComponent } from './components/dialogs/duplicar-materia-personalizada/duplicar-materia-personalizada.component';
import { ModificarMateriaPersonalizadaComponent } from './components/dialogs/modificar-materia-personalizada/modificar-materia-personalizada.component';
import { ReasignarGrupoComponent } from './components/dialogs/reasignar-grupo/reasignar-grupo.component';
import { CrearOtraMateriaComponent } from './components/dialogs/crear-otra-materia/crear-otra-materia.component';
import { ConfigurarClaseComponent } from './components/dialogs/configurar-clase/configurar-clase.component';
import { OcultarUnidadAlumnadoComponent } from './components/dialogs/ocultar-unidad-alumnado/ocultar-unidad-alumnado.component';
import { ActualizarContrasenyaComponent } from './components/dialogs/actualizar-contrasenya/actualizar-contrasenya.component';
import { ValidarLicenciaComponent } from './components/dialogs/validar-licencia/validar-licencia.component';
import { DescargarNotasComponent } from './components/dialogs/descargar-notas/descargar-notas.component';
import { HighlightsComponent } from './components/highlights/highlights.component';
import { ModificarTareaMoodleComponent } from './components/dialogs/modificar-tarea-moodle/modificar-tarea-moodle.component';
import { RepetirTareaOActividadComponent } from './components/dialogs/repetir-tarea-o-actividad/repetir-tarea-o-actividad.component';
import { EnviarIncidenciasComponent } from './components/dialogs/enviar-incidencias/enviar-incidencias.component';
import { IncidenciasComponent } from './components/incidencias/incidencias.component';
import { OnboardingComponent } from './components/dialogs/onboarding/onboarding.component';
import { MisClasesModalComponent } from './components/dialogs/mis-clases/mis-clases.component';
import { TareaVencidaComponent } from './components/dialogs/tarea-vencida/tarea-vencida.component';
import { ResetModuloComponent } from './components/reset-modulo/reset-modulo.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MaterialModule,
    DirectivesModule,
    TranslateModule,
    SafeModule,
    CKEditorModule,
    ClipboardModule,
    CdkAccordionModule,
    DragDropModule,
    NgxPrintModule
  ],
  declarations: [
    BigInputComponent,
    BigInputActionComponent,
    RtlSupportDirective,
    CrearTemaNuevoComponent,
    ProgramacionAulaComponent,
    MensajeNotificacionComponent,
    DesasignarAlumnadoComponent,
    OcultarComponent,
    EliminarComponent,
    MiClaseComponent,
    DescargarOfflineComponent,
    DuplicarComponent,
    CopiarComponent,
    MoverComponent,
    DescargarPdfComponent,
    DescargarCsvComponent,
    EliminarComponent,
    StartCompartirComponent,
    CopiarEnlaceComponent,
    MoverCopiarElementoComponent,
    EnviarComentarioComponent,
    EditarMetadatosComponent,
    ImagenDestacadaComponent,
    TareaComponent,
    ModificarTareaComponent,
    ModificarTareaMoodleComponent,
    DuplicarTareaComponent,
    AdjuntarFicheroComponent,
    GuiaDidacticaComponent,
    RepetirTareaComponent,
    RepetirTareaOActividadComponent,
    AddSeccionComponent,
    AddSubSeccionComponent,
    GenericComponent,
    ConfigurarAulaplanetaComponent,
    AsignarTareaComponent,
    AsignarTareaRapidaComponent,
    AsignarTareaClassroomComponent,
    AsignarTareaTeamsComponent,
    CrearEnlaceComponent,
    NotFoundComponent,
    SubirImagenComponent,
    EditarRecursoComponent,
    ReasignarTareaComponent,
    NuevoGrupoAlumnosComponent,
    FiltrarResultadosComponent,
    ModificarTemaComponent,
    AsignarUnidadComponent,
    CambiarVistaComponent,
    CambiarContrasenaComponent,
    ConfiguracionCookiesComponent,
    EditarRecursoConfirmComponent,
    ConfigurarOtrasMateriasComponent,
    ConfigurarClaseComponent,
    CrearMateriaComponent,
    AddRecursoComponent,
    RepetirActividadComponent,
    // EditionHeaderComponent,
    EditionBarComponent,
    EditionAddComponent,
    SeccionComponent,
    AudioComponent,
    EnlaceComponent,
    DestacadoComponent,
    DocumentoComponent,
    ImagenComponent,
    TablaComponent,
    VideoComponent,
    TextoComponent,
    JumboTemaComponent,
    AnnotationsComponent,
    ResetModuloComponent,
    AddAnnotationComponent,
    EditRecursoPlantillaComponent,
    ExpositorDialogComponent,
    DrawerDialogComponent,
    PlayerComponent,
    SecsToMinsPipe,
    MisTemasPipe,
    CrearMateriaPersonalizadaComponent,
    DuplicarMateriaPersonalizadaComponent,
    ModificarMateriaPersonalizadaComponent,
    ReasignarGrupoComponent,
    CrearOtraMateriaComponent,
    OcultarUnidadAlumnadoComponent,
    ActualizarContrasenyaComponent,
    ValidarLicenciaComponent,
    DescargarNotasComponent,
    HighlightsComponent,
    EnviarIncidenciasComponent,
    IncidenciasComponent,
    OnboardingComponent,
    MisClasesModalComponent,
    TareaVencidaComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    BigInputComponent,
    BigInputActionComponent,
    RtlSupportDirective,
    ClipboardModule,
    DragDropModule,
    SafeModule,
    NgxPrintModule,
    EditionBarComponent,
    EditionAddComponent,
    AnnotationsComponent,
    ResetModuloComponent,
    PlayerComponent,
    HighlightsComponent,
    IncidenciasComponent
  ]
})
export class SharedModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(
      faGithub,
      faMediumM,
      faPlus,
      faEdit,
      faTrash,
      faTimes,
      faCaretUp,
      faCaretDown,
      faExclamationTriangle,
      faFilter,
      faTasks,
      faCheck,
      faSquare,
      faLanguage,
      faPaintBrush,
      faLightbulb,
      faWindowMaximize,
      faStream,
      faBook
    );
  }
}
