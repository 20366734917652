/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ColorHelper } from '../../../helper/color.helper';
import { BuscadorManagementService } from '../../../../services/data/buscador/buscador-management.service';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { TemasManagementService } from '../../../../services/data/temas/temas-management.service';
import * as _ from 'lodash';
import { EnvService } from '../../../../services/env.service';

@Component({
  selector: 'aula-planeta-add-recurso',
  templateUrl: 'add-recurso.component.html',
  styleUrls: ['./add-recurso.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddRecursoComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') private stepperSection: MatStepper;

  firstStep: UntypedFormGroup;
  secondStep: UntypedFormGroup;
  currentStep = 0;
  selection: SelectionModel<null>;

  dataRecursos = [];
  urlImg = 'Relaciona_M1c';

  filterControl = new UntypedFormControl();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('selectorProgreso') selectorProgreso: MatSelect;
  filtroRelevancia = 'mayor-relevancia';

  private destroy$: Subject<void> = new Subject<void>();

  tipoRecurso = 'curso';
  tipoRecurso2 = 'curso';
  criteriosTipo = [
    { value: 'curso', viewValue: 'Ordenar por Curso' },
    { value: 'asignatura', viewValue: 'Ordenar por Asignatura' },
    { value: 'dificultad', viewValue: 'Ordenar por dificultad' }
  ];

  ordenRecurso = 'asc';
  ordenRecurso2 = 'asc';
  criteriosOrden = [
    { value: 'asc', viewValue: 'Ascendente' },
    { value: 'desc', viewValue: 'Descendente' }
  ];

  filter = '';
  filter2 = '';


  error = {
    imagen_previa: false,
    imagen: false,
    documento: false,
    audio: false
  };

  validTypes = {
    imagen: ['image/gif', 'image/jpeg', 'image/jpg', 'image/pipeg', 'image/png'],
    imagen_previa: ['image/gif', 'image/jpeg', 'image/jpg', 'image/pipeg', 'image/png'],
    documento: [
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.apple.pages',
      'application/vnd.apple.keynote',
      'application/vnd.apple.numbers',
      'text/plain'
    ],
    audio: ['audio/mpeg', 'audio/mp4', 'audio/vnd.wav', 'audio/ogg']
  };


  recursosBanco = [
    {
      id: 1,
      titulo: 'Veniam nulla amet quis tempor qui cupid.',
      descripcion: 'Qui aute fugiat fugiat in ad Lorem labore laborum occaecat non dolor adipisicing. Est exercitation est adipisicing.',
      miniatura: 'https://picsum.photos/300/300',
      dificultad: 3,
      curso: '1º ESO',
      materia: {
        nombre: 'Ciencias de la naturaleza',
        color: 'verdeCN'
      },
      grupo: [
        'Maichel Maichelson',
        'Davinsi Davinson',
        'Roger Rogerson',
        'Xavi Xavierson',
        'Ramon Ramonsinson',
        'Arturo Arturerson',
        'John Doe'
      ]
    },
    {
      id: 2,
      titulo: 'Officia adipisicing es.',
      descripcion: 'Ea non dolore cillum proident voluptate dolor proident eu. Voluptate reprehenderit esse commodo fugiat velit.',
      miniatura: 'https://picsum.photos/300/300',
      dificultad: 2,
      curso: '2º ESO',
      materia: {
        nombre: 'Matemáticas',
        color: 'rojoM'
      },
      grupo: [
        'Maichel Maichelson',
        'Davinsi Davinson',
        'Roger Rogerson'
      ]
    },
    {
      id: 3,
      titulo: 'Sint duis aliqua officia.',
      descripcion: 'Sunt amet ex eiusmod aliqua culpa exercitation esse. Duis eu ipsum non proident proident nisi eu eu.',
      miniatura: 'https://picsum.photos/300/300',
      dificultad: 1,
      curso: '3º ESO',
      materia: {
        nombre: 'História',
        color: 'marronMS'
      },
      grupo: [
        'John Doe'
      ]
    },
    {
      id: 4,
      titulo: 'Consequat enim mol.',
      descripcion: 'Anim commodo anim mollit fugiat eu. Ut est ipsum consectetur consectetur deserunt quis deserunt eu eu magna.',
      miniatura: 'https://picsum.photos/300/300',
      dificultad: 0,
      curso: '4º ESO',
      materia: {
        nombre: 'Química y Física',
        color: 'verdeFQ'
      },
      grupo: []
    },
    {
      id: 5,
      titulo: 'Enim irure aliqua sunt aliqua in reprehenderit laboris duis.',
      descripcion: 'Reprehenderit voluptate proident excepteur incididunt nisi adipisicing adipisicing culpa .',
      miniatura: 'https://picsum.photos/300/300',
      dificultad: 3,
      curso: '1º ESO',
      materia: {
        nombre: 'Literatura',
        color: 'lilaL'
      },
      grupo: [
        'Maichel Maichelson',
        'Davinsi Davinson',
        'Roger Rogerson',
        'Xavi Xavierson',
        'Ramon Ramonsinson',
        'Arturo Arturerson',
        'John Doe'
      ]
    },
    {
      id: 6,
      titulo: 'Officia sint veniam labore laborum culpa consectetur cupidatat et dolore pariatur.',
      descripcion: 'Ea adipisicing proident fugiat cillum velit enim sint deserunt qui anim irure ad exercitation.',
      miniatura: 'https://picsum.photos/300/300',
      dificultad: 1,
      curso: '2º ESO',
      materia: {
        nombre: 'Tecnología',
        color: 'naranjaT'
      },
      grupo: []
    }
  ];
  dataRecursosBanco = this.recursosBanco;

  // OPTION 2
  plantillasRecursos = [
    {
      id: 4,
      type: 'Relaciona_M1c',
      name: 'Relacionar columnas'
    },
    {
      id: 5,
      type: 'Preguntas_M4a',
      name: 'Seleccionar la respuesta correcta'
    },
    {
      id: 6,
      type: 'Abiertas_M101a',
      name: 'Responder preguntas abiertas'
    },
    {
      id: 7,
      type: 'Rellena_M2c',
      name: 'Rellenar huecos'
    },
    {
      id: 8,
      type: 'Proyecto_M102ab',
      name: 'Descargar actividad desde un archivo'
    },
    {
      id: 9,
      type: 'Multimotor_M16a',
      name: 'Responder preguntas cerradas combinadas'
    }
  ];
  plantillaSelected = null;

  // OPTION 3
  typeRecursos = [
    {
      value: 1,
      viewValue: 'Enlace'
    },
    {
      value: 2,
      viewValue: 'Documento'
    },
    {
      value: 3,
      viewValue: 'Video'
    },
    {
      value: 4,
      viewValue: 'Imagen'
    },
    {
      value: 5,
      viewValue: 'Audio'
    }

  ]
  file;
  fileForm: UntypedFormGroup;
  textLimit = 120;


  private _imagen: BehaviorSubject<string> = new BehaviorSubject<string>('');
  imagen$ = this._imagen.asObservable();

  private _imagen_previa: BehaviorSubject<string> = new BehaviorSubject<string>('');
  imagen_previa$ = this._imagen_previa.asObservable();



  constructor(
    public envService: EnvService,
    private fb: UntypedFormBuilder,
    public buscadorManagementService: BuscadorManagementService,
    public temasManagementService: TemasManagementService,
    public colorHelper: ColorHelper,
    public dialogRef: MatDialogRef<AddRecursoComponent>,
    public cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data,
    private gtagService: GtagService
  ) {
    this.selection = new SelectionModel(false, []);
  }

  ngOnInit() {
    this.firstStep = this.fb.group({
      sectionType: [1, Validators.required]
    });

    this.secondStep = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      fileName: [''],
      file: ['', Validators.required],
      url: ['', Validators.required],
      imagenName: [''],
      imagen_previaName: [''],
      imagen: [''],
      imagen_previa: [''],
      creditos: [''],
      creditos_html: [''],
      embeded: [''],
      visible: [''],
      texto_alternativo: [''],
      pie_de_imagen: [''],
      type: null
    });
    this.filterControl.valueChanges.pipe(
      debounceTime(750),
      takeUntil(this.destroy$)
    ).subscribe(value => {
      if (value.length > 2) {
        this.buscar(value);
      }
    });
    this.buscadorManagementService.recursos$.pipe(
      takeUntil(this.destroy$)).subscribe(recursos => {
        if (recursos && this.filterControl.value) {
          this.dataRecursos = recursos;
          this.filtrarRecursos();
          setTimeout(() => {
            this.cd.detectChanges();
          }, 252);
        }
      });

    const info = {
      event: 'popup_steps',
      popupSteps: {
        action: 'add_resource',
        popupId: 'dialog-add-recurso',
        popupName: 'popup_add_resource',
        popupStep: this.currentStep.toString()
      }
    };
    this.gtagService.event(info);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routeWindow(item: any) {
    // eslint-disable-next-line max-len
    // window.open(`http://localhost:4200/preview/materia/${item.materiaId}/tema/${item.temaId}/recurso/${item.id}`, '_blank');
    window.open(`${this.envService.rootContentNoCDN}/preview/materia/${item.materiaId}/tema/${item.temaId}/recurso/${item.id}`, '_blank');
    const info = {
      event: 'page_view',
      pageView: {
        imPage: 'recursos_buscador',
        pageId: 'recursos_buscador',
        imPagetitle: 'Recursos tarea',
        pageTitle: 'Recursos tarea'
      }
    };
    this.gtagService.pageview(info);
  }


  onStepperChange(ev) {
    this.currentStep = ev.selectedIndex;
    if (this.currentStep === 0) {
      this.secondStep.controls['title'].setValue('');
      this.secondStep.controls['description'].setValue('');
      this.secondStep.removeControl('file');
      this.secondStep.removeControl('url');
      this.secondStep.removeControl('embeded');
      this.secondStep.removeControl('imagen');
      this.secondStep.removeControl('imaegn_previa');
      this.secondStep.controls['type'].setValue(null);
      this.selection.clear();

      this.plantillaSelected = 0;
    }
    this.secondStep.updateValueAndValidity();

    const info = {
      event: 'popup_steps',
      popupSteps: {
        action: 'anterior/siguiente',
        popupId: 'dialog-add-recurso',
        popupName: 'popup_añadir_recurso',
        popupStep: this.currentStep.toString()
      }
    };

    this.gtagService.event(info);
  }

  goBack() {
    this.stepperSection.previous();
    this.currentStep = 0;
  }

  goNext() {
    this.stepperSection.next();
    this.currentStep = 1;
  }

  onClose() {
    let result = {};

    if (this.firstStep.controls['sectionType'].value === 3) {
      result = { recurso: this.secondStep.value };
      this.dialogRef.close({ ...this.firstStep.value, ...result });
    } else if (this.firstStep.controls['sectionType'].value === 2) {
      result = { plantilla: this.plantillaSelected.type };
      this.dialogRef.close({ ...this.firstStep.value, ...result });
    } else {
      const recurso = this.selection.selected[0] as any;
      this.temasManagementService.getRecursoWorkaround(recurso.temaId, recurso.id).pipe(
        take(1),
        takeUntil(this.destroy$))
        .subscribe(
          modulo => {
            // uri = uri.Replace(protocolo, string.Empty).Replace(".zip", string.Empty);

            // url = $"{aulaURLs.ContenidosURL}/{uri}/{C.Contenido.Modulo.ENTRY_POINT_PRELOAD}{aulaURLs.ModulosURL}{modulo.Id}/";
            if (
              modulo.propiedades.tipoActividad &&
              modulo.propiedades.tipoActividad.valor.player &&
              modulo.propiedades.tipoActividad.valor.player.includes('fs1://') &&
              !modulo.propiedades.actividad
            ) {
              const paso1 = modulo.propiedades.tipoActividad.valor.player.replaceAll('fs1://', '');
              const paso2 = paso1.replaceAll('.zip', '');
              const paso3 = `/Contenido/${paso2}/Principal.html?_preload_data=/Contenido/Modulos/${modulo.id}/`;
              modulo.propiedades.actividad = paso3;
              console.error(`El modulo ${modulo.id} viene con la url del SCORM mal formada`);
            }
            result = { recursoBuscador: modulo };
            this.dialogRef.close({ ...this.firstStep.value, ...result });
          }
        );
    }

  }

  checkboxLabel(row?): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row `;
  }

  filtrarRelevancia(opcion: MatSelectChange) {
    if (opcion.value === 'mayor-relevancia') {
      this.sortData('ASC');
    } else if (opcion.value === 'menor-relevancia') {
      this.sortData('DESC');
    } else {
      this.sortData('ASC');
    }
  }

  filtrarRecursos() {
    this.selection.clear();
    const isAsc = this.ordenRecurso === 'asc';
    const prop = this.tipoRecurso === 'asignatura' ? 'materiaNombre' : this.tipoRecurso;
    this.dataRecursos = this.dataRecursos.sort((a, b) => this.compare(a[prop], b[prop], isAsc));
  }

  filtrarRecursosBanco() {
    this.selection.clear();
    const isAsc = this.ordenRecurso2 === 'asc';
    const prop = this.tipoRecurso2 === 'asignatura' ? 'materia.nombre' : this.tipoRecurso2;
    this.dataRecursosBanco = this.dataRecursosBanco.sort((a, b) => this.compare(a[prop], b[prop], isAsc));
  }

  sortData(sortDirection) {
    const isAsc = sortDirection === 'ASC';
    this.plantillasRecursos = this.plantillasRecursos.sort((a, b) => this.compare(a.id, b.id, isAsc));
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (typeof a === 'string') {
      a = _.deburr(a).toLowerCase().trim();
    }
    if (typeof b === 'string') {
      b = _.deburr(b).toLowerCase().trim();
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  search(data, filterValue) {
    if (!filterValue) {
      return data;
    } else {

      return data.filter((v) =>
        v.titulo.toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
        v.descripcion.toLowerCase().indexOf(filterValue.toLowerCase()) > -1
      );
    }
  }

  buscar(value) {
    if (value && value.length > 1) {
      this.buscadorManagementService.getRecursosAnadir(value);
    }
  }

  selectPlantilla(val) {
    this.urlImg = val.type;
    this.plantillaSelected = val;
  }

  changeTipoRecurso(option) {

    if (option.value === 1) {
      this.secondStep.removeControl('embeded');
      this.secondStep.removeControl('file');
      this.secondStep.addControl('url', this.fb.control(''));
    } else if (option.value === 2) {
      this.secondStep.removeControl('embeded');
      this.secondStep.removeControl('url');
      this.secondStep.addControl('file', this.fb.control('', [Validators.required]));
      this.secondStep.addControl('embeded', this.fb.control(''));
    } else if (option.value === 3) {
      this.secondStep.removeControl('file');
      this.secondStep.removeControl('url');
      this.secondStep.addControl('embeded', this.fb.control(''));
    } else if (option.value === 4) {
      this.secondStep.removeControl('file');
      this.secondStep.removeControl('url');
      this.secondStep.removeControl('embeded');
      this.secondStep.addControl('imagen', this.fb.control(''));
      this.secondStep.addControl('imagen_previa', this.fb.control(''));
      this.secondStep.addControl('creditos', this.fb.control(''));
      this.secondStep.removeControl('url');
    } else if (option.value === 5) {
      this.secondStep.addControl('file', this.fb.control('', [Validators.required]));
      this.secondStep.removeControl('url');
      this.secondStep.removeControl('embeded');
    }
    this.secondStep.updateValueAndValidity();
  }

  onDrop(file, field) {
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file as any);
      reader.onloadend = () => {
        // this._photo.next(reader.result as string);
        if (field === 'documento') {
          this.secondStep.get('file').setValue(reader.result);
        } else {
          this.secondStep.get('file').setValue(reader.result as string);
        }

        this.secondStep.get('file' + 'Name').setValue(file.name);
        this.secondStep.markAsDirty();
        this.secondStep.updateValueAndValidity();
        this.cd.markForCheck();
      };
    }
  }

  deleteFile() {
    this.secondStep.patchValue({
      file: null
    });

    this.secondStep.get('file').updateValueAndValidity();
  }


  onDropFile(e, field) {
    this.error[field] = false;
    const file = (e as HTMLInputElement);

    if (this.isValidFile(file, field)) {
      this.onDrop(file, field);
    } else {
      this.error[field] = true;
      this.deleteFile();
    }
  }

  onDropImg(file, field) {
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (field === 'imagen') {
          this._imagen.next(reader.result as string);
        } else {
          this._imagen_previa.next(reader.result as string);
        }
        this.secondStep.get(field).setValue(reader.result as string);
        this.secondStep.get(field + 'Name').setValue(file.name);
        this.secondStep.markAsDirty();
        this.secondStep.updateValueAndValidity();
      };
    }
  }

  deleteImg(field) {
    this.secondStep.get(field).setValue(null);
    this.secondStep.get(field).updateValueAndValidity();
    if (field === 'imagen') {
      this._imagen.next(null);
    } else {
      this._imagen_previa.next(null);
    }
  }

  isValidFile(file, field) {
    return this.validTypes[field].includes(file.type) || !file.type;
  }
}
