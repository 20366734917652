<div id="dialog-modificar-tarea" class="standard d-flex flex-column">
    <h1 mat-dialog-title class="custom-text mb-4 font-weight-bold">{{ 'dialogs.asignar-tarea.title' | translate }}</h1>
     <div mat-dialog-content class="mat-dialog-content">
        <mat-horizontal-stepper [linear]="true" #stepper class="default-stepper" selectedIndex="1" (selectionChange)="onStepperChange($event)">
            <mat-step [completed]="true" [editable]="false" label="{{ 'dialogs.asignar-tarea.step1' | translate}}"></mat-step>

            <mat-step [stepControl]="secondStep" label="{{ 'dialogs.asignar-tarea.step2' | translate}}">
                <!-- <h2 class="custom-text my-4">{{ 'dialogs.asignar-tarea.step2.intro' | translate }}</h2> -->

                <div class="overflow-auto fluid-content">
                    <form [formGroup]="secondStep">
                        <fieldset class="w-100 d-flex flex-column">

                          <legend class="custom-text my-4">{{ 'dialogs.asignar-tarea.step2.intro' | translate }}</legend>

                            <div class="d-flex">

                                <div class="w-50">
                                    <div class="w-100 form-group default-input mb-4">
                                        <label for="nombreTarea">{{ 'dialogs.asignar-tarea.step2.nombre-tarea' | translate }}</label>
                                        <input tabindex="0" id="nombreTarea" aria-labelledby="errorNombreTarea" type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.nombre-tarea' | translate)" formControlName="name" cdkFocusInitial>
                                        <mat-error id="errorNombreTarea" *ngIf="secondStep.get('name').hasError('required') && secondStep.controls['name'].dirty">
                                            {{'generico.required' | translate}}
                                        </mat-error>
                                    </div>

                                    <div class="d-flex">
                                        <div class="w-100 form-group default-textarea mb-4">
                                            <label for="comentario">{{'dialogs.asignar-tarea.step2.comentario' | translate}}</label>
                                            <textarea id="comentario" aria-labelledby="errorComentario" type="text" class="form-control" [placeholder]="('dialogs.asignar-tarea.step2.comentario' | translate)" formControlName="comentario" maxlength="255"></textarea>
                                            <span class="text-secondary"><small>{{'('+ secondStep.controls['comentario'].value.length +'/255)'}}</small></span>
                                            <mat-error id="errorComentario" *ngIf="secondStep.get('comentario').hasError('required') && secondStep.controls['comentario'].dirty">
                                                {{'generico.required' | translate}}
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="date-container w-50 ms-4">
                                    <div class="date-inicio-container d-flex">
                                        <div  class="form-group default-input mb-4 position-relative" [ngClass]="{ 'w-100': !mostrarHoras, 'w-50': mostrarHoras }">
                                            <label for="fechaInicio">{{ 'dialogs.asignar-tarea.step2.fecha-inicio' | translate }}</label>
                                            <input
                                              id="fechaInicio"
                                              type="text"
                                              class="form-control"
                                              [min]="minDate"
                                              [max]="maxDate"
                                              [matDatepicker]="pickerInicio"
                                              (click)="pickerInicio.open()"
                                              [placeholder]="('dialogs.asignar-tarea.step2.fecha-inicio' | translate)"
                                              formControlName="inicio">
                                            <mat-datepicker-toggle matSuffix [for]="pickerInicio" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerInicio startView="year" [startAt]="minDate"></mat-datepicker>
                                        </div>

                                        <div *ngIf="mostrarHoras && !isLTI() " class="default-input position-relative ms-4">
                                            <label class="invisible">hora</label>
                                            <input class="form-control"
                                              type="time"
                                              step="300"
                                              id="horaInicio"
                                              formControlName="horaInicio">
                                            <mat-error style="font-size: small;" *ngIf="secondStep.hasError('fechaHoraInvalida')">
                                                {{'dialogs.asignar-tarea.step2.fecha-hora-invalida' | translate}}
                                            </mat-error>
                                        </div>
                                    </div>

                                    <div class="date-fin-container d-flex">
                                        <div  class="form-group default-input mb-4 position-relative"  [ngClass]="{ 'w-100': !mostrarHoras, 'w-50': mostrarHoras }">
                                            <label for="fechaFin">{{ 'dialogs.asignar-tarea.step2.fecha-fin' | translate }}</label>
                                            <input
                                              id="fechaFin"
                                              type="text"
                                              class="form-control"
                                              [min]="secondStep.get('inicio').value"
                                              [max]="maxDate"
                                              [matDatepicker]="pickerFin"
                                              (click)="pickerFin.open()"
                                              [placeholder]="('dialogs.asignar-tarea.step2.fecha-fin' | translate)"
                                              formControlName="fin">
                                            <mat-datepicker-toggle matSuffix [for]="pickerFin" class="datepicker-icon default-text position-absolute"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerFin startView="year" [startAt]="secondStep.get('inicio').value"></mat-datepicker>
                                        </div>

                                        <div *ngIf="mostrarHoras"  class="default-input  position-relative">
                                            <label class="invisible">hora</label>
                                            <input matInput
                                              class="form-control"
                                              type="time" step="300" id="fechaFin" value="10:00" [min]="secondStep.get('horaInicio').value"
                                              formControlName="horaFin">
                                        </div>
                                    </div>

                                    <ng-container *ngIf="!isLTI()">
                                        <mat-slide-toggle (change)="onToggleChange($event)" [checked]="mostrarHoras">
                                            Mostrar Horas
                                        </mat-slide-toggle>
                                    </ng-container>
                                </div>
                            </div>



                            <!-- <div class="w-100 d-flex">
                          <div class="form-group">
                              <mat-slide-toggle class="grey-text" disableRipple="true" formControlName="shared">{{ 'dialogs.asignar-tarea.step2.shared' | translate }}</mat-slide-toggle>
                          </div>
                      </div> -->

                        </fieldset>
                    </form>
                </div>
            </mat-step>

            <mat-step [stepControl]="thirdStep1 && thirdStep2" label="{{ 'dialogs.asignar-tarea.step3' | translate}}">
                <h2 class="custom-text my-4">{{ 'dialogs.asignar-tarea.step3.intro' | translate }}</h2>

                <div class="overflow-auto">
                    <!-- <form [formGroup]="thirdStep"> -->
                    <mat-tab-group class="standard" (selectedTabChange)="tabChange($event)">
                        <!-- <mat-tab label="{{ 'dialogs.asignar-tarea.step3.select-grupo' | translate}}">
                        <form [formGroup]="thirdStep1">
                            <fieldset class="w-100 d-flex flex-column my-4">
                                <div class="form-group w-35">
                                    <label class="default-text">{{ 'dialogs.asignar-tarea.step3.mis-grupos' | translate }}</label>
                                    <mat-select cdkFocusInitial class="default-select default-select__blue" [placeholder]="'dialogs.asignar-tarea.step3.mis-grupos' | translate" formControlName="grupo" panelClass="cdkSelect">
                                        <mat-optgroup *ngFor="let grupo of grupos" class="default-group-options">
                                            <mat-option class="default-option" *ngFor="let item of grupo" [value]="item.id">
                                                {{item.id}}
                                            </mat-option>
                                        </mat-optgroup>
                                    </mat-select>
                                </div>
                            </fieldset>
                        </form>
                    </mat-tab> -->

                        <mat-tab label="{{ 'dialogs.asignar-tarea.step3.select-alumnos' | translate}}">
                            <form [formGroup]="thirdStep2">
                                <fieldset class="w-100 d-flex flex-column flex-md-row justify-content-between my-4">
                                    <div class="d-flex flex-column w-35">
                                        <div class="form-group">
                                            <label for="grupo" class="default-text">{{ 'dialogs.asignar-tarea.step3.grupo' | translate }}</label>
                                            <mat-select id="grupo" cdkFocusInitial class="default-select default-select__blue" (selectionChange)="setAlumnosListMulti()" [placeholder]="'dialogs.asignar-tarea.step3.grupo' | translate" formControlName="curso" panelClass="cdkSelect" multiple>
                                                <mat-optgroup *ngFor="let grupo of grupos" class="default-group-options">
                                                    <mat-option id="allOptions" class="default-option" *ngFor="let _grupo of grupo" [value]="_grupo">
                                                        {{_grupo.nombre}}
                                                    </mat-option>
                                                </mat-optgroup>
                                            </mat-select>
                                        </div>
                                        <div class="form-group mb-3">
                                            <mat-slide-toggle class="grey-text" *ngIf="habilitarSeleccionManualAlumnos" disableRipple="true" (change)="seleccionManualAlumnos = !seleccionManualAlumnos; enableCustomGroup = false; desasignarTodos(); setAlumnosListMulti()">{{ 'dialogs.asignar-tarea.step3.seleccion-manual' | translate }}</mat-slide-toggle>
                                        </div>
                                        <div class="form-group" *ngIf="seleccionManualAlumnos">
                                            <mat-slide-toggle class="grey-text" disableRipple="true" (change)="enableCustomGroup = !enableCustomGroup; ">{{ 'dialogs.asignar-tarea.step3.create-grupo' | translate }}</mat-slide-toggle>
                                        </div>
                                        <div *ngIf="enableCustomGroup && seleccionManualAlumnos" class="form-group default-input">
                                            <label for="grupoPersonalizado" class="default-text">{{ 'dialogs.asignar-tarea.step3.nombre-grupo-personalizado' | translate }}</label>
                                            <input id="grupoPersonalizado" type="text" class="form-control" formControlName="nombreGrupo" [placeholder]="('dialogs.asignar-tarea.step3.nombre-grupo-personalizado' | translate)">
                                        </div>
                                    </div>

                                    <div *ngIf="seleccionManualAlumnos" class="alumnos-container w-60 d-flex flex-column flex-md-row mb-4">
                                        <div class="w-100 h-100 alumnos-container__list">
                                            <label class="w-100 pb-2 mb-0 default-text">{{ 'mi-centro.gestion-contrasenas.alumnos-disponibles' | translate }}</label>
                                            <div class="overflow-y-auto">
                                                <div *ngFor="let item of alumnos" class="my-3">
                                                    <mat-checkbox class="ms-3" (click)="$event.stopPropagation()" (change)="$event ? selectionDisponibles.toggle(item) : null " [checked]="selectionDisponibles.isSelected(item)" [aria-label]="item.nombreCompleto">
                                                        {{item.nombre}} {{item.apellidoUno}} {{item.apellidoDos}}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="alumnos-container__actions d-flex flex-row flex-md-column justify-content-center align-content-center px-4">
                                            <button mat-icon-button class="custom-text" (click)="asignarAlumnos()" aria-label="Asignar alumnos">
                                            <mat-icon>chevron_right</mat-icon>
                                        </button>
                                            <button mat-icon-button class="custom-text" (click)="asignarTodos()" aria-label="Asignar todos">
                                            <mat-icon>last_page</mat-icon>
                                        </button>
                                            <button mat-icon-button class="custom-text" (click)="desasignarTodos()" aria-label="Desasignar todos">
                                            <mat-icon>first_page</mat-icon>
                                        </button>
                                            <button mat-icon-button class="custom-text" (click)="desasignarAlumnos()" aria-label="Desasignar alumnos">
                                            <mat-icon>chevron_left</mat-icon>
                                        </button>
                                        </div>
                                        <div class="w-100 alumnos-container__list">
                                            <label class="w-100 pb-2 mb-0 default-text">{{ 'mi-centro.gestion-contrasenas.alumnos-seleccionados' | translate }}</label>
                                            <div>
                                                <div *ngFor="let item of alumnosAsignados" class="my-3">
                                                    <mat-checkbox class="ms-3" (click)="$event.stopPropagation()" (change)="$event ? selectionAsignados.toggle(item) : null " [checked]="selectionAsignados.isSelected(item)" [aria-label]="item.nombreCompleto">
                                                        {{item.nombre}} {{item.apellidoUno}} {{item.apellidoDos}}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </fieldset>
                            </form>
                        </mat-tab>
                    </mat-tab-group>
                    <!-- </form> -->
                </div>

            </mat-step>
        </mat-horizontal-stepper>
    </div>
    <div mat-dialog-actions class="justify-content-between">
        <button role="button" class="default-button default-button__primary" mat-dialog-close>{{ 'dialogs.create-theme.cancel' | translate }}</button>

        <div class="d-flex">
            <button role="button" class="default-button default-button__primary ms-3" [disabled]="currentStep <= 1" (click)="goBack()">{{ 'generico.anterior' | translate}}</button>
            <button role="button" class="default-button default-button__secondary ms-3" *ngIf="currentStep <= 1" [disabled]="!secondStep.valid" (click)="goNext()">{{ 'generico.siguiente' | translate}}</button>
            <button role="button" class="default-button default-button__secondary ms-3" (click)="onClose()" *ngIf="currentStep >= 2" [disabled]="alumnosAsignados.length < 1">{{ 'generico.guardar' | translate }}</button>
        </div>
    </div>

</div>
