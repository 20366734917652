<ng-container *ngIf="(tareaManagementService.alumnosActividades$ | async) as alumnos">

    <mat-toolbar *ngIf="!temasManagementService.fullscreen" color="primary" class="d-flex justify-content-between align-items-center">

        <div class="d-flex align-items-center">
            <ng-container>
                <div *ngIf="!(ltiManagementService.moodle$ | async) && !isEvaluacionMicrosoft()" class="default-breadcrumb d-flex" id="breadcrumbs">
                    <div *ngFor="let breadcrumb of breadcrumbService.breadcrumbs$ | async" [ngClass]="breadcrumb.color">
                        <span class="custom-text" [ngClass]="breadcrumb.link ? 'cursor-pointer fade-in' : 'fade-in' " (click)="breadcrumbService.navigateLink(breadcrumb.link); breadcrumbService.breadCrumbClickAction(breadcrumb.link)" [innerHtml]="breadcrumb.value | safe:'html'"></span>
                    </div>
                </div>
            </ng-container>
        </div>

    </mat-toolbar>

    <div class="d-flex flex-wrap flex-column flex-lg-row justify-content-center actividad position-relative {{temasManagementService.fullscreen ? 'super-fullscreen' : ''}}" [ngClass]="color">
        <div *ngIf="!temasManagementService.fullscreen" id="evaluacion" class="w-40">
            <div class="mt-4">
                <div class="expansible-jumbotron" [ngClass]="{'expansible-jumbotron__full': smartlinkManagementService.smartlink}">

                    <div *ngIf="!alumnos[alumnoIndex].tareas[tareaIndex].autonoma" class="d-flex justify-content-between mb-2" role="region" aria-labelledby="titleTarea">
                        <h1 class="d-flex flex-column">
                            <mat-card-subtitle class="default-text font-weight-bold mb-1" rtl id="titleTarea">{{'seguimiento-alumno.tarea.titulo' | translate}}</mat-card-subtitle>
                            <mat-card-title class="custom-text heading-6" rtl>{{alumnos[alumnoIndex].tareas[tareaIndex].titulo}}</mat-card-title>
                        </h1>
                        <ng-template [hasRole]="['Alumno']">
                            <mat-icon *ngIf="alumnos[alumnoIndex].tareas[tareaIndex].repetir" class="repetir-icon" matTooltipPosition="below" [matTooltip]="'materia.tareas.tabla.acciones.repetir' | translate">restart_alt</mat-icon>
                        </ng-template>
                    </div>

                    <div class="d-flex flex-column mb-2 w-100 filter-input" role="region" aria-labelledby="titleActividad">
                        <div class="d-flex flex-column">
                            <h2>
                                <mat-card-subtitle aria-live="polite" class="default-text font-weight-bold mb-0 pt-3" id="titleActividad" [ngClass]="actividad.estado == 2 || showRepetir ? 'd-flex align-items-center' : ''" rtl>
                                    {{'seguimiento-alumno.tareas-alumno.tabla.alumno' | translate}}
                                </mat-card-subtitle>
                            </h2>
                            <div class="d-flex justify-content-between">
                                <h3>
                                    <mat-card-title class="custom-text heading-6" rtl>{{actividad.titulo}}</mat-card-title>
                                </h3>
                                <mat-paginator #paginatorAct *ngIf="!alumnos[alumnoIndex].tareas[tareaIndex].autonoma" aria-live="polite" class="default-text" [length]="alumnos[alumnoIndex].tareas[tareaIndex].actividades.length" [pageIndex]="actividadIndex" [pageSize]="1" [pageSizeOptions]="[1]"
                                    [attr.aria-label]="'seguimiento-alumno.tarea.paginador' | translate" (click)="changeActividad(paginatorAct, $event)">
                                </mat-paginator>
                            </div>
                        </div>
                    </div>

                    <ng-template [hasRole]="['Profesor']">
                        <div class="d-flex flex-column mb-2 w-100 filter-input" *ngIf="!(ltiManagementService.moodle$ | async)" role="region" aria-labelledby="titleAlumnado">
                            <mat-card-subtitle aria-live="polite" class="default-text font-weight-bold mb-0 pt-3" id="titleAlumnado" rtl>
                                {{'seguimiento-alumno.tareas-alumno.titulo' | translate}}
                            </mat-card-subtitle>
                            <div class="d-flex  justify-content-between">
                                <div class="d-flex align-items-start pe-3 pt-2">
                                    <div class="d-flex align-items-center">
                                        <div class="thumb-img d-flex justify-content-center align-items-center">
                                            <img sanitize-img type="avatar" [absolute]="false" [src]="alumnos[alumnoIndex].avatar | safe:'url'" alt="" height="40" width="40" class="cover photo">
                                            <span class="d-none">
                                                {{alumnos[alumnoIndex]?.persona?.nombre?.charAt(0)}}{{alumnos[alumnoIndex]?.persona?.apellidoUno?.charAt(0)}}
                                            </span>
                                        </div>

                                        <span class="font-weight-bold grey-text cursor-pointer">{{alumnos[alumnoIndex].persona.nombre}} {{alumnos[alumnoIndex].persona.apellidoUno}} {{alumnos[alumnoIndex].persona.apellidoDos}}</span>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <mat-paginator #paginatorAlu *ngIf="!alumnos[alumnoIndex].tareas[tareaIndex].autonoma" class="default-text" aria-live="polite" [length]="alumnos.length" [pageIndex]="alumnoIndex" [pageSize]="1" [pageSizeOptions]="[1]" [attr.aria-label]="'seguimiento-alumno.tarea.paginador' | translate"
                                        (click)="changeAlumno(paginatorAlu, $event)">
                                    </mat-paginator>






                                    <!-- <button (click)="repetir(alumnos[alumnoIndex].tareas[tareaIndex], alumnos[alumnoIndex], $event)" matTooltipPosition="below" [matTooltip]="'materia.tareas.tabla.acciones.repetir' | translate" [attr.aria-label]="'materia.tareas.tabla.acciones.repetir' | translate"
                                        mat-icon-button class="position-relative default-button default-button--circle default-text restart_alt">
                                            <mat-icon>replay</mat-icon>
                                        </button>



                                    <button (click)="repetirActividad( alumnos[alumnoIndex].tareas[tareaIndex])" [ngClass]="isCorregida(actividad) ? 'default-button' : 'grey-text__lightest'" matTooltipPosition="below" [matTooltip]="'seguimiento-alumno.tarea.repetir-actividad' | translate"
                                        [attr.aria-label]="'seguimiento-alumno.tarea.repetir-actividad' | translate" mat-icon-button class="default-button--circle default-text d-flex align-items-center justify-content-center me-1">
                                            <mat-icon>loop</mat-icon>
                                        </button> -->


                                    <!-- <ng-template *ngIf="actividad.esScorm" [hasRole]="['Profesor']">
                                        <button (click)="repetir(alumnos[alumnoIndex].tareas[tareaIndex], alumnos[alumnoIndex], $event)" matTooltipPosition="below" [matTooltip]="'materia.tareas.tabla.acciones.repetir' | translate" [attr.aria-label]="'materia.tareas.tabla.acciones.repetir' | translate"
                                            mat-icon-button class="position-relative default-button default-button--circle default-text restart_alt">
                                            <mat-icon>replay</mat-icon>
                                        </button>
                                    </ng-template>

                                    <ng-template [hasRole]="['Profesor']">
                                        <button *ngIf="!alumnos[alumnoIndex].tareas[tareaIndex].autonoma" [disabled]="!isCorregida(actividad)" (click)="repetirActividad(actividad.id, alumnos[alumnoIndex].id, alumnos[alumnoIndex].tareas[tareaIndex])" [ngClass]="isCorregida(actividad) ? 'default-button' : 'grey-text__lightest'"
                                            matTooltipPosition="below" [matTooltip]="'seguimiento-alumno.tarea.repetir-actividad' | translate" [attr.aria-label]="'seguimiento-alumno.tarea.repetir-actividad' | translate" mat-icon-button class="default-button--circle default-text d-flex align-items-center justify-content-center me-1">
                                            <mat-icon>loop</mat-icon>
                                        </button>
                                    </ng-template> -->

                                    <ng-template [hasRole]="['Profesor']">
                                        <button role="button" type="submit" (click)="repetir(alumnos[alumnoIndex].tareas[tareaIndex], alumnos[alumnoIndex], $event, actividad.esScorm, alumnos[alumnoIndex].tareas[tareaIndex].autonoma, actividad.id, alumnos[alumnoIndex].id, actividad.titulo)"
                                            class="my-3 default-button default-button__secondary d-flex align-items-center" [disabled]="!actividad.esScorm">
                                            <mat-icon class="me-5">loop</mat-icon>{{'materia.tareas.tabla.acciones.repetir' | translate}}</button>
                                    </ng-template>


                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <form id="dialog-mi-clase" [formGroup]="form">
                        <fieldset>
                            <legend class="visually-hidden">{{'seguimiento-alumno.actividad.estado' | translate}} & {{'seguimiento-alumno.actividad.nota' | translate}}</legend>
                            <div class="d-flex flex-column mb-2 w-100 filter-input">
                                <div class="d-flex justify-content-between flex-wrap pt-3">
                                    <div  class="form-group default-input default-input--white default-input--xl w-40 mb-3">
                                        <label for="nota" rtl>{{'seguimiento-alumno.actividad.nota' | translate}}</label>
                                        <p class="m-0"></p>
                                        <mat-icon [ngClass]="{'w-0':!(actividad.estado == 4 && actividad.evidencias[0]?.nota?.valor === null)}">{{(actividad.estado == 4 && actividad.evidencias[0]?.nota?.valor === null) ? 'remove' : ''}}</mat-icon>
                                        <ng-template [hasRole]="['Profesor']">
                                            <input id="nota" type="number" [ngClass]="{'danger-border': actividad.estado === 4}" class="form-control input-nota" formControlName="nota" [step]="0.1" [min]="0" [max]="notaMaxima" matInput name="nota" [readonly]="authManagementService.userCanRoles(['Alumno'])"
                                            />
                                        </ng-template>
                                        <ng-template [hasRole]="['Alumno']">
                                            <ng-container *ngIf="actividad.evidencias[0]?.mostrarNota">
                                                <span [ngClass]="{'grey-text__30': actividad.estado === 4, 'grey-text': actividad.estado !== 4}" class="text__nota px-3">{{ form.get('nota').value | replace : '.' : ',' }}</span>
                                            </ng-container>
                                        </ng-template>
                                        <div *ngIf="form.get('nota').invalid && form.get('nota').errors && (form.get('nota').dirty || form.get('nota').touched)">
                                            <small class="text-danger" *ngIf="form.get('nota').hasError('required') && !form.pristine">
                                                {{'generico.required' | translate}}
                                            </small>
                                            <small class="text-danger" *ngIf="form.get('nota').hasError('min')">
                                                {{'generico.minvalue' | translate:{minvalue: 0} }}
                                            </small>
                                            <small class="text-danger" *ngIf="form.get('nota').hasError('max')">
                                                {{'generico.maxvalue' | translate:{maxvalue: form.get('nota').errors.max.max} }}
                                            </small>
                                        </div>
                                    </div>
                                    <div *ngIf="!(ltiManagementService.moodle$ | async)" class="mt-2 form-group default-input d-flex align-items-start justify-content-start flex-column">
                                        <span class="label">{{'seguimiento-alumno.actividad.estado' | translate}}</span>
                                        <span [ngClass]="{'danger-text': actividad.estado === 4, 'black-text': actividad.estado !== 4}" class="body-4 m-0">
                                            <ng-template [hasRole]="['Profesor']">{{'seguimiento-alumno.actividad.estado.'+actividad.estado | translate}}</ng-template>
                                            <ng-template [hasRole]="['Alumno']">{{'seguimiento-alumno.actividad.estado.alumno.'+actividad.estado | translate}}</ng-template>
                                        </span>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-between me-2" *ngIf="!(ltiManagementService.moodle$ | async)">
                                        <!-- <ng-template [hasRole]="['Profesor']">
                                                <button mat-icon-button (click)="adjuntar()" class="default-button default-button--circle default-text">
                                                    <mat-icon class="ms-1">attach_file</mat-icon>
                                                </button>
                                            </ng-template> -->
                                    </div>
                                </div>

                                <div class="form-group default-textarea default-textarea--white w-100 mb-2">
                                    <label for="comentario">{{'dialogs.enviar-comentario.comentario' | translate}}</label>
                                    <textarea type="text" class="form-control" formControlName="comentario" id="comentario" placeholder="{{ 'dialogs.enviar-comentario.comentario' | translate }}" [attr.aria-label]="'dialogs.enviar-comentario.comentario' | translate" [readonly]="authManagementService.userCanRoles(['Alumno']) || (authManagementService.userCanRoles(['Profesor']) && (actividad.estado === 3 || actividad.estado === 0  ))">
                                    </textarea>
                                </div>
                                <!-- <mat-card-subtitle class="mt-3 default-text font-weight-bold mb-0" rtl>{{'dialogs.enviar-comentario.comentario' | translate}}</mat-card-subtitle>
                                    <textarea matInput class="mt-2 form-control"  ></textarea> -->

                                <ng-template [hasRole]="['Profesor']">
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                        <mat-slide-toggle *ngIf="!(ltiManagementService.moodle$ | async)" class="grey-text" formControlName="saveOnChange" [checked]="tareaManagementService.saveOnChange"> {{'seguimiento-alumno.tarea.siguiente-actividad' | translate}} </mat-slide-toggle>
                                        <button role="button" type="submit" (click)="evaluar(alumnos[alumnoIndex].tareas[tareaIndex], $event)" class="default-button default-button__secondary" [disabled]="!form.valid || form.pristine">{{'generico.guardar' | translate}}</button>
                                    </div>
                                </ng-template>
                                <!-- <mat-slide-toggle class="example-margin" [checked]="envService.postManUrl" (change)="envService.postManUrl = !envService.postManUrl">
                                        Ir a siguiente actividad al guardar
                                    </mat-slide-toggle> -->
                            </div>


                        </fieldset>
                    </form>




                    <ng-container *ngIf="(adjuntos$ | async)?.length > 0">

                        <div class="d-flex flex-column mb-0 w-100 filter-input docs-attached">
                            <p class="d-flex mt-3 mb-0 py-2 w-100 align-items-center justify-content-between">
                                <mat-card-subtitle class="custom-text font-weight-bold mb-0" rtl>{{'seguimiento-alumno.tarea.titulo.documentos' | translate}}</mat-card-subtitle>
                                <button class="more-arrow d-flex align-items-center" [ngClass]="isScrolling ? 'd-none' : ''" matTooltipPosition="below" [matTooltip]="moreDocs ? ('generico.colapsar' | translate) : ('generico.expandir' | translate)" [attr.aria-label]="moreDocs ? ('generico.colapsar' | translate) : ('generico.expandir' | translate)"
                                    [attr.aria-expanded]="moreDocs ? 'true' : 'false'" (click)="moreDocs=! moreDocs">
                                  <mat-icon class="cursor-pointer custom-text"
                                      [ngClass]="moreDocs ? 'expanded' : ''">expand_more</mat-icon>
                                </button>
                            </p>

                            <div class="more-data overflow-hidden docs-attached" [ngClass]="isScrolling ? 'd-none' : 'd-flex flex-wrap'" [@detailExpand]="moreDocs ? 'expanded' : 'collapsed'">



                                <div class="w-100 docs-attached__item d-flex align-items-center justify-content-between py-2" *ngFor="let doc of adjuntos$ | async">
                                    <div class="d-flex align-items-center">
                                        <mat-icon mat-list-icon class="grey-icon">insert_drive_file</mat-icon>
                                        <span class="ps-2 grey-text text__small" [innerHTML]="doc.titulo_fichero | safe:'html'"></span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <mat-icon class="grey-icon" [ngClass]="{'invisible': doc.visible_alumno }" matTooltipPosition="below" [matTooltip]="'dialogs.add-module-base.form.visible-profesor' | translate">group_off</mat-icon>
                                        <button (click)="link(doc.nombre_fichero, $event, alumnos)" matTooltipPosition="below" [matTooltip]="'recursos.ver-documento' | translate" aria-label="'recursos.ver-documento' | translate" mat-icon-button class="ms-2 custom-button custom-icon">
                                            <mat-icon>launch</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="w-55 mb-5" id="actividad" [ngClass]="{'ms-0': temasManagementService.fullscreen}">
            <div class="scorm-container" [ngClass]="openedScorm ? 'open' : 'mt-4'">
                <div id="ifrcontainer" class="d-flex p-0 scorm-boundary" [ngClass]="openedScorm ? 'open' : ''">
                    <div *ngIf="!startedActivity" class="position-absolute d-flex align-items-center justify-content-center w-100 h-100" style="background-color: transparent; z-index: 9000">
                        <button *ngIf="!showSpinner" (click)="realizarActividad(alumnos[alumnoIndex].tareas[tareaIndex].autonoma, actividad.id, alumnos[alumnoIndex].tareas[tareaIndex].id)" class="position-relative play-button custom-icon z-index-up" mat-icon-button matTooltipPosition="below"
                            [matTooltip]="'generico.empezar' | translate">
                            <mat-icon class="text__xxl">play_circle_filled</mat-icon>
                        </button>
                    </div>
                    <!-- <div *ngIf="showSpinner" class="position-absolute d-flex align-items-center justify-content-center w-100 h-100" style="background-color: transparent; z-index: 9000">
                        <mat-spinner *ngIf="showSpinner" class="custom-spinner" diameter="80" [attr.aria-label]="'generico.cargando' | translate"></mat-spinner>
                    </div> -->

                    <iframe #iframe id="material" title="{{actividad.titulo}}" (load)="loadIframe();
                        showCaducada()" [src]="urlSafe" [ngClass]="!startedActivity ? 'blur-active' : ''" style="background-image: url('assets/images/spinner.gif'); background-position: center; background-repeat: no-repeat; background-color: #b2b2b2">
                    </iframe>

                    <button
                      (click)="openScorm($event)"
                      [ngClass]="{'scorm-button-opened': openedScorm, 'scorm-button-closed': !openedScorm, 'youtube': isYoutube, 'position-fixed': openedScorm, 'position-absolute': !openedScorm}"
                      [attr.aria-label]="'generico.zoom' | translate"
                      class="default-button--circle custom-bg__regular scorm-button ">
                        <mat-icon
                          class="white-text"
                          matTooltipPosition="below"
                          [matTooltip]="'generico.zoom' | translate">
                            {{ openedScorm ? 'zoom_in_map' : 'crop_free' }}
                        </mat-icon>
                    </button>
                </div>
            </div>

            <mat-tab-group *ngIf="!temasManagementService.fullscreen" mat-align-tabs="start" class="mt-4" (click)="gtagTabInfo($event)">
                <ng-template [hasRole]="['Profesor']">
                    <mat-tab *ngIf="actividad.fichaProfesor?.length > 0" [label]="'seguimiento-alumno.tarea.ficha.profesor' | translate">
                        <div class="actividad-container__ficha mt-4" [innerHTML]="watchHeaders(actividad.fichaProfesor) | safe:'html'"></div>
                    </mat-tab>
                </ng-template>
                <mat-tab *ngIf="actividad.fichaAlumno?.length > 0" [label]="'seguimiento-alumno.tarea.ficha.alumno' | translate">
                    <div class="actividad-container__ficha mt-4">
                        <ng-container *ngIf="actividad.fichaAlumno; else noFichaActividad">
                            <div [innerHTML]="watchHeaders(actividad.fichaAlumno) | safe: 'html'"></div>
                        </ng-container>
                        <ng-template #noFichaActividad></ng-template>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>

    </div>

</ng-container>
