import { Injectable } from '@angular/core';
import { environment as environmentProd } from '../../environments/environment.prod';
import { environment as environmentUat } from '../../environments/environment.uat';
import { environment as environmentStaging } from '../../environments/environment.staging';
import { environment as environmentEdi } from '../../environments/environment.edi';
import { environment } from '../../environments/environment';
import { NgForage } from 'ngforage';
import { LocalStorageService } from '../core/core.module';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EnvService {

  bancoUri: string;
  private destroy$: Subject<void> = new Subject<void>();


  constructor(private readonly ngf: NgForage,
    private localStorageService: LocalStorageService) {
    this.localStorageService.getItem('CURRENT_USER').pipe(takeUntil(this.destroy$))
      .subscribe((data) => this.bancoUri = data?.entornos?.urlBanco);

  };



  private _rootContent: string;
  private _rootContentNoCDN: string;
  private _rootContentFind: string;
  private _rootContentRocket: string;
  private _apiUrl: string;
  private _bancoUri: string;
  private _worldbookUri: string;
  private _maticUri: string;
  private _teamsClientID: string;
  private _teamsScope: string;

  get rootContent(): string {
    return this._rootContent;
  }

  get rootContentNoCDN(): string {
    return this._rootContentNoCDN;

  }

  get rootContentFind(): string {
    return this._rootContentFind;
  }

  get rootContentRocket(): string {
    return this._rootContentRocket;
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  // get bancoUri(): string {
  //   return this._bancoUri;
  // }

  get worldbookUri(): string {
    return this._worldbookUri;
  }

  get maticUri(): string {
    return this._maticUri;
  }

  get teamsClientID(): string {
    return this._teamsClientID;
  }

  get teamsScope(): string {
    return this._teamsScope;
  }

  // get postManUrl(): boolean {
  //   this._postManUrl = localStorage.getItem('POSTMAN') === 'true' ? true : false;
  //   return this._postManUrl;
  // }

  // set postManUrl(value: boolean) {
  //   this._postManUrl = value;
  //   localStorage.setItem('POSTMAN', value + '');
  //   this.setEnvVariables();
  // }


  init(): Promise<void> {
    return new Promise(resolve => {
      this.setEnvVariables();
      resolve();
    });
  }

  private setEnvVariables(): void {

    const hostname = window && window.location && window.location.hostname;
    console.log('setEnvVariables', hostname);

    if (/^.*localhost.*/.test(hostname)) {
      this._apiUrl = environment.api;
      this._rootContent = environment.rootContent;
      this._rootContentNoCDN = environment.rootContentNoCDN;
      this._rootContentFind = environment.rootContentFind;
      this._rootContentRocket = environment.rootContentRocket;
      this._bancoUri = this.bancoUri;
      this._worldbookUri = environment.worldbookUri;
      this._maticUri = environment.maticUri;
      this._teamsClientID = environment.teamsClientId;
      this._teamsScope = environment.teamsScope;
    } else if (/^uat/.test(hostname)) {
      this._apiUrl = environmentUat.api;
      this._rootContent = environmentUat.rootContent;
      this._rootContentNoCDN = environmentUat.rootContentNoCDN;
      this._rootContentFind = environmentUat.rootContentFind;
      this._rootContentRocket = environmentUat.rootContentRocket;
      this._bancoUri = this.bancoUri;
      this._worldbookUri = environmentUat.worldbookUri;
      this._maticUri = environmentUat.maticUri;
      this._teamsClientID = environment.teamsClientId;
      this._teamsScope = environment.teamsScope;
    } else if (/^staging/.test(hostname)) {
      this._apiUrl = environmentStaging.api;
      this._rootContent = environmentStaging.rootContent;
      this._rootContentNoCDN = environmentStaging.rootContentNoCDN;
      this._rootContentFind = environmentStaging.rootContentFind;
      this._rootContentRocket = environmentStaging.rootContentRocket;
      this._bancoUri = this.bancoUri;
      this._worldbookUri = environmentStaging.worldbookUri;
      this._maticUri = environmentStaging.maticUri;
      this._teamsClientID = environment.teamsClientId;
      this._teamsScope = environment.teamsScope;
    } else if (/^edi/.test(hostname)) {
      this._apiUrl = environmentEdi.api;
      this._rootContent = environmentEdi.rootContent;
      this._rootContentNoCDN = environmentEdi.rootContentNoCDN;
      this._rootContentFind = environmentEdi.rootContentFind;
      this._rootContentRocket = environmentEdi.rootContentRocket;
      this._bancoUri = this.bancoUri;
      this._worldbookUri = environmentEdi.worldbookUri;
      this._maticUri = environmentEdi.maticUri;
      this._teamsClientID = environment.teamsClientId;
      this._teamsScope = environment.teamsScope;
    } else if (/^web/.test(hostname)) {
      this._apiUrl = environmentProd.api;
      this._rootContent = environmentProd.rootContent;
      this._rootContentNoCDN = environmentProd.rootContentNoCDN;
      this._rootContentFind = environmentProd.rootContentFind;
      this._rootContentRocket = environmentProd.rootContentRocket;
      this._bancoUri = this.bancoUri;
      this._worldbookUri = environmentProd.worldbookUri;
      this._maticUri = environmentProd.maticUri;
      this._teamsClientID = environment.teamsClientId;
      this._teamsScope = environment.teamsScope;
    } else if (/^int/.test(hostname)) {
      this._apiUrl = environment.api;
      this._rootContent = environment.rootContent;
      this._rootContentNoCDN = environment.rootContentNoCDN;
      this._rootContentFind = environment.rootContentFind;
      this._rootContentRocket = environment.rootContentRocket;
      this._bancoUri = this.bancoUri;
      this._worldbookUri = environment.worldbookUri;
      this._maticUri = environment.maticUri;
      this._teamsClientID = environment.teamsClientId;
      this._teamsScope = environment.teamsScope;
    } else {
      this._apiUrl = environment.api;
      this._rootContent = environment.rootContent;
      this._rootContentNoCDN = environment.rootContentNoCDN;
      this._rootContentFind = environment.rootContentFind;
      this._rootContentRocket = environment.rootContentRocket;
      this._bancoUri = this.bancoUri;
      this._worldbookUri = environment.worldbookUri;
      this._maticUri = environment.maticUri;
      this._teamsClientID = environment.teamsClientId;
      this._teamsScope = environment.teamsScope;
    }
    console.log('this._apiUrl', this._apiUrl);


    // if (this.postManUrl) {
    //   this._apiUrl = 'https://2a460849-9052-48f1-b356-2bfd45a78612.mock.pstmn.io/api';
    // }
  }
}